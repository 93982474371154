import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  TAB_LAYERS,
  TAB_TRENDS,
  TAB_EXPLORE,
  TAB_DOWNLOAD,
} from 'reducers/nav';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Map as LeafletMap, TileLayer, ZoomControl } from 'react-leaflet';
import styled from 'styled-components';
import { poppinMixin } from 'util/styled';
import { setPointOfInterest } from 'actions/layers';
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN, MAP_DEFAULT_ZOOM } from 'config/mapbox';
import { setMapViewport } from 'actions/map';
import { sendAnalyticsEvent } from 'util/analytics';
import Layers from './layers';
import Explore from './explore';
import Download from './download';
import LocateControl from './locate';
import satelliteViewImg from './images/base-map-button-satellite-view.png';
import streetViewImg from './images/base-map-button-street-view.png';

const MAP_STREET = 'MAP_1';
const MAP_SATELLITE = 'MAP_2';

const MAP_STREET_URL = `https://api.mapbox.com/styles/v1/salo-cba/ck9wzygix02os1io34hlll17l/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;
const MAP_SATELLITE_URL = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

const MapButton = styled.div`
  position: absolute;
  left: ${(props) => (props.navOpen ? 325 : 30)}px;
  bottom: 30px;
  width: 72px;
  height: 72px;
  border-radius: 4px;
  padding-top: 55px;
  background-size: 72px;
  background-position: center;
  z-index: 999;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
`;

const MapSatellite = styled(MapButton)`
  background-image: url(${satelliteViewImg});
  color: #fff;
  @media only screen and (max-device-width: 767px){
    display: none;
  }
`;

const MapStreet = styled(MapButton)`
  background-image: url(${streetViewImg});
  color: #242424;
`;

const RootMap = ({ className }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => state.nav.tab);
  const navOpen = useSelector((state) => state.nav.open);
  const viewport = useSelector((state) => state.map.viewport);
  const [currentMap, setCurrentMap] = useState(MAP_STREET);
  // const [latLng, setLatLng] = useState(null);

  const onStreetMapSelected = (e) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;

    sendAnalyticsEvent('base_map_selected', { map_name: 'street' });
    setCurrentMap(MAP_STREET);
  };

  const onSatelliteMapSelected = (e) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;

    sendAnalyticsEvent('base_map_selected', { map_name: 'satellite' });
    setCurrentMap(MAP_SATELLITE);
  };

  let baseMap;
  let mapButton;
  if (currentMap === MAP_STREET) {
    baseMap = <TileLayer url={MAP_STREET_URL} />;
    mapButton = (
      <MapSatellite
        navOpen={navOpen}
        role="button"
        tabIndex={0}
        onClick={onSatelliteMapSelected}
        onKeyPress={onSatelliteMapSelected}
      >
        Satellite
      </MapSatellite>
    );
  } else {
    baseMap = <TileLayer url={MAP_SATELLITE_URL} />;
    mapButton = (
      <MapStreet
        navOpen={navOpen}
        role="button"
        tabIndex={0}
        onClick={onStreetMapSelected}
        onKeyPress={onStreetMapSelected}
      >
        Street View
      </MapStreet>
    );
  }

  let mapOverlays;
  let onMapClick;
  if (tab === TAB_LAYERS) {
    mapOverlays = <Layers tab={tab} />;
    onMapClick = (e) => dispatch(setPointOfInterest(e.latlng.lat, e.latlng.lng));
  } else if (tab === TAB_TRENDS) {
    mapOverlays = <Layers tab={tab} />;
    onMapClick = (e) => dispatch(setPointOfInterest(e.latlng.lat, e.latlng.lng));
  } else if (tab === TAB_EXPLORE) {
    mapOverlays = <Explore />;
  } else if (tab === TAB_DOWNLOAD) {
    mapOverlays = <Download />;
    onMapClick = () => {};
  }

  return (
    <div className={className}>
      {mapButton}
      <LeafletMap
        animate
        zoomControl={false}
        minZoom={MAP_DEFAULT_ZOOM}
        style={{ width: '100vw', height: '100%', position: 'absolute' }}
        onClick={onMapClick}
        maxBounds={MAP_BOUNDS}
        viewport={viewport}
        onViewportChange={(newViewport) => dispatch(setMapViewport(newViewport))}
      >
        {baseMap}
        {currentMap === MAP_STREET
          && (
          <TileLayer
            zIndex={2}
            url="https://api.mapbox.com/styles/v1/salo-cba/ck7l3r84h07xd1intnedvwd0i/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2Fsby1jYmEiLCJhIjoiY2pwMjcwcHZrMDQ2MTN1bnY4b2RrbjllMSJ9.nv4JZUvZNTvnAEw3KdmXzA"
          />
          )}

        {mapOverlays}

        <LocateControl />
        {!isMobile && <ZoomControl position="bottomright" />}
      </LeafletMap>
    </div>
  );
};
RootMap.propTypes = {
  className: PropType.string.isRequired,
};
export const Map = styled(RootMap)`
  .hoverPopup .leaflet-popup-content {
    ${poppinMixin(12)}
    margin: 5px !important;
  }
  .hoverPopup .leaflet-popup-tip-container,
  .leaflet-popup-close-button {
    display: none !important;
  }
  .hoverPopup .leaflet-popup-content-wrapper {
    border-radius: 5px;
  }
  .leaflet-control-locate, .leaflet-control-zoom {
    bottom: 40px;
  }
`;

export default Map;
