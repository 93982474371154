import {
  OPEN_NAV,
  CLOSE_NAV,
  SET_TAB,
} from 'actions';

export const openNav = () => ({
  type: OPEN_NAV,
});

export const closeNav = () => ({
  type: CLOSE_NAV,
});

export const setTab = (tab) => ({
  type: SET_TAB,
  payload: tab,
});
