import React from 'react';
import PropTypes from 'prop-types';
import { clearPointOfInterest } from 'actions/layers';
import { Marker, Popup } from 'react-leaflet';
import { LAYERS, YEARS } from 'config/layers';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import styled from 'styled-components';
import { poppinMixin, ORANGE_1 } from 'util/styled';
import bird from './images/bird-pin.png';

export const findLayerById = (id, layers) => {
  let result = null;
  Object.values(layers).forEach((layer) => YEARS.forEach((year) => {
    const layerConfig = LAYERS[layer.layerType];
    if (year in layerConfig && layerConfig[year].layers === id) {
      result = layer;
    }
  }));

  return result;
};

const birdIcon = new L.Icon({
  iconUrl: bird,
  iconAnchor: [17, 60],
  iconSize: [32, 60],
  popupAnchor: [95, -40],
});

const StyledPopup = styled(Popup)`
  ${poppinMixin(12)}

  .leaflet-popup-content {
    margin: 5px !important;
  }
  .leaflet-popup-tip-container,
  .leaflet-popup-close-button {
    display: none !important;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 5px 5px 5px 0;
  }
`;

const DataRow = ({ className, name, value }) => (
  <dl className={className}>
    <dt>{name}</dt>
    <dd>{value}</dd>
  </dl>
);
DataRow.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
const StyledDataRow = styled(DataRow)`
  display: flex;
  margin-bottom: 5px;
  ${poppinMixin(12)}

  dt {
    flex-grow: 1;
    margin-right: 5px;
  }
  
  dd {
    ${poppinMixin(12, 'bold')}
    color: ${ORANGE_1} !important;
  }
`;

const DataPoint = ({ poi }) => {
  const { lat, lng } = poi;

  useSelector((state) => state.layers.lastUpdated);
  const layers = useSelector((state) => state.layers.layers);
  const visibleLayerTypes = useSelector(
    (state) => state.layers.visibleLayerTypes,
  );
  const dispatch = useDispatch();

  const layerKeys = Object.keys(poi)
    .filter((p) => p !== 'lat')
    .filter((p) => p !== 'lng');
  const visibleKeys = layerKeys.filter((k) => {
    const layer = findLayerById(k, layers);
    return layer.visibility && LAYERS[layer.layerType][layer.year].layers === k;
  });
  // Sort the names to match the ordering of the map overlays:
  const nameAndValues = visibleKeys
    .map((k) => [
      LAYERS[findLayerById(k, layers).layerType].name,
      poi[k],
      k,
      findLayerById(k, layers).layerType,
    ])
    .sort(
      (a, b) => visibleLayerTypes.indexOf(b[3]) - visibleLayerTypes.indexOf(a[3]),
    );

  const openPopup = (marker) => {
    if (nameAndValues.length > 0 && marker && marker.leafletElement) {
      marker.leafletElement.openPopup();
    }
  };

  return (
    <Marker
      key={`${lat},${lng}`}
      position={[lat, lng]}
      ref={openPopup}
      icon={birdIcon}
      onClick={() => dispatch(clearPointOfInterest())}
    >
      {nameAndValues.length > 0 ? (
        <StyledPopup autoClose={false} minWidth={144} autoPan={false}>
          {nameAndValues.map(([name, value, key]) => (
            <StyledDataRow key={key} name={name} value={value} />
          ))}
        </StyledPopup>
      ) : null}
    </Marker>
  );
};

DataPoint.propTypes = {
  poi: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
};

export default DataPoint;
