import { UPDATE_TRENDS_POI } from 'actions';
import { requestDataForTrend } from 'services';
import TRENDS from 'config/trends';
import { dateDiff } from 'util/date';

export const setTrendsPointOfInterest = (lat, lng) => async (dispatch) => {
  const trendsData = await Promise.all(TRENDS.map(async (trend) => {
    const responses = await Promise.all([...Array(7)].map(async (_, i) => {
      const date = dateDiff(i - 4);
      try {
        const response = await requestDataForTrend(trend, date, lat, lng);
        let value = null;
        if (typeof response.data.val === 'number') {
          value = response.data.val;
        }

        const dateAsInt = +date;
        return {
          date: dateAsInt,
          value,
        };
      } catch (e) {
        // on failure, don't update the POI.
        return null;
      }
    }));

    return [trend.id, responses.filter((response) => response !== null)];
  }));

  dispatch({
    type: UPDATE_TRENDS_POI,
    payload: trendsData.reduce(
      (obj, [key, value]) => {
        // eslint-disable-next-line
        obj[key] = value;
        return obj;
      },
      {},
    ),
  });
};

export default setTrendsPointOfInterest;
