import React, { useState } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { requestPasswordReset } from 'services';
import Popup from 'containers/popup';
import { Button, Text, GRAY_1 } from 'util/styled';
import styled from 'styled-components';
import { ROUTE_LAYERS } from 'config/routes';
import { StyledForm, ErrorMessage } from './signin';

const Root = styled.div`
  margin-top: 75px;

  ${Text} {
    margin-bottom: 30px;
  }
`;

const ResetPasswordRequestPopup = ({ error: defaultError, thanks: defaultThanks }) => {
  const [thanks, setThanks] = useState(defaultThanks);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(defaultError);
  const history = useHistory();

  const onFinish = (values) => {
    setLoading(true);
    requestPasswordReset(values.email)
      .then(() => {
        setThanks(true);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Popup
      width={500}
      height={thanks ? 350 : 480}
      onClosePopup={() => history.push(ROUTE_LAYERS)}
      showLogo
    >
      <Root aria-label="Reset Password popup">
        <Text size={14} weight="bold" transform="uppercase" color={GRAY_1}>
          Reset Your Password
        </Text>
        {!thanks && (
          <div>
            <Text size={12} color={GRAY_1}>
              Enter your email address and we will send you a link to reset your
              password.
            </Text>
            <StyledForm onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Email address',
                  },
                ]}
              >
                <Input placeholder="email" aria-label="Forgot Password Email input" />
              </Form.Item>
              {error && (
                <ErrorMessage aria-label="Invalid Forgot Password email error message">
                  Sorry, that email isn&apos;t valid. Please try again.
                </ErrorMessage>
              )}
              <Form.Item>
                <Button loading={loading} htmlType="submit" full>
                  Submit
                </Button>
              </Form.Item>
            </StyledForm>
          </div>
        )}
        {thanks && (
          <Text size={12} weight="500" color={GRAY_1} aria-label="Forgot Password successful message">
            Help is on the way. Please check your email to reset your password.
          </Text>
        )}
      </Root>
    </Popup>
  );
};
ResetPasswordRequestPopup.propTypes = {
  thanks: PropType.bool,
  error: PropType.bool,
};
ResetPasswordRequestPopup.defaultProps = {
  thanks: false,
  error: false,
};

export default ResetPasswordRequestPopup;
