import { yearMonthDay } from 'util/date';

const HAZARD = {
  id: 'Exposure',
  name: 'Wildfire Exposure',
  unit: 'Unitless',
  catalog: 'cfo',
  asset_id: (date) => `California-Wildfire-${HAZARD.id}-${yearMonthDay(date)}-00150m`,
};

const WIND = {
  id: 'WindSpeed',
  name: 'Wind Speed',
  unit: 'MPH',
  catalog: 'cfo',
  asset_id: (date) => `California-Weather-${WIND.id}-${yearMonthDay(date)}-03000m`,
};

const TEMPERATURE = {
  id: 'Temperature',
  name: 'Temperature',
  unit: 'Degrees F',
  catalog: 'cfo',
  asset_id: (date) => `California-Weather-${TEMPERATURE.id}-${yearMonthDay(date)}-03000m`,
};

const HUMIDITY = {
  id: 'Humidity',
  name: 'Humidity',
  unit: 'Percent',
  catalog: 'cfo',
  asset_id: (date) => `California-Weather-${HUMIDITY.id}-${yearMonthDay(date)}-03000m`,
};

const TRENDS = [HAZARD, WIND, TEMPERATURE, HUMIDITY];

export default TRENDS;
