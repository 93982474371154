import React, { useEffect, useRef } from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import objectFitImages from 'object-fit-images';
import { poppinMixin, GRAY_5, ORANGE_1 } from 'util/styled';
import { ANNUAL_LAYERS } from 'config/layers';

const Root = styled.div`
  background: #fff;
  padding: 20px 20px 55px 20px;
  ${poppinMixin(14, '600')}

  .title {
    margin-bottom: 20px;
  }

  .datasets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
  }
`;

const DatasetRoot = styled.button`
  border: none;
  background-color: transparent;
  text-align: center;
  margin: 0 10px 40px 0;
  position: relative;
  padding: 0;

  :focus {
    outline: none;
  }

  .name {
    position: absolute;
    bottom: 10;
    left: 50%;
    margin-left: -52%;
    width: 105%;
    ${(props) => (props.selected ? poppinMixin(12, '500', ORANGE_1) : poppinMixin(12, '500'))}
  }

  img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
    width: 90px;
    height: 90px;
    border: 1px solid ${(props) => (props.selected ? ORANGE_1 : GRAY_5)};
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
  }
`;

export const Dataset = ({ layerConfig, selected, onChange }) => {
  const datasetImage = useRef();

  useEffect(() => {
    objectFitImages(datasetImage.current);
  }, [datasetImage]);

  return (
    <DatasetRoot selected={selected} onClick={() => onChange(layerConfig.layerType)}>
      <img
        ref={datasetImage}
        src={layerConfig.imagePath}
        alt={layerConfig.name}
      />
      <div className="name">{layerConfig.name}</div>
    </DatasetRoot>
  );
};

Dataset.propTypes = {
  layerConfig: PropType.shape({
    name: PropType.string.isRequired,
    layerType: PropType.string.isRequired,
    imagePath: PropType.string.isRequired,
  }).isRequired,
  selected: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
};

const Datasets = ({ selectedLayerTypes, onChange }) => (
  <Root>
    <div className="title">Available datasets:</div>
    <div className="datasets" aria-label="Download tab datasets">
      {ANNUAL_LAYERS.map((layerConfig) => (
        <Dataset
          key={layerConfig.layerType}
          layerConfig={layerConfig}
          selected={selectedLayerTypes.includes(layerConfig.layerType)}
          onChange={onChange}
        />
      ))}
    </div>
  </Root>
);
Datasets.propTypes = {
  selectedLayerTypes: PropType.arrayOf(PropType.string).isRequired,
  onChange: PropType.func.isRequired,
};

export default Datasets;
