import { combineReducers } from 'redux';
import nav from './nav';
import user from './user';
import layers from './layers';
import trends from './trends';
import welcome from './welcome';
import map from './map';
import explore from './explore';
import downloads from './downloads';
import route from './route';

export default combineReducers({
  nav,
  user,
  layers,
  trends,
  welcome,
  map,
  explore,
  downloads,
  route,
});
