const makeSourceUrl = (typeName) => `http://maps.salo.ai:8080/geoserver/cfo/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${typeName}&maxFeatures=10000&outputFormat=application%2Fjson`;

const REGION_COUNTY = {
  name: 'Counties',
  geographyType: 'County',
  url: `${process.env.PUBLIC_URL}/Counties.topojson`,
  sourceUrl: makeSourceUrl('cfo%3ACounties'),
};

const REGION_MUNICIPALITY = {
  name: 'Communities',
  geographyType: 'Municipality',
  url: `${process.env.PUBLIC_URL}/Communities.topojson`,
  sourceUrl: makeSourceUrl('cfo%3AMunicipalities'),
};

const REGION_WATERSHED = {
  name: 'Watersheds',
  geographyType: 'Watershed',
  url: `${process.env.PUBLIC_URL}/Watersheds.topojson`,
  sourceUrl: makeSourceUrl('cfo%3AWatersheds'),
};

export const REGIONS = [REGION_COUNTY, REGION_MUNICIPALITY, REGION_WATERSHED];

export default REGIONS;
