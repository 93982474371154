import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'actions/user';
import Popup from 'containers/popup';
import { Button, Text } from 'util/styled';
import {
  ROUTE_TOUR,
  ROUTE_ABOUT,
  ROUTE_CONTACT,
  ROUTE_LEGAL,
} from 'config/routes';
import PropType from 'prop-types';
import styled from 'styled-components';
import { Menu, HeaderLink, AboutLink } from './menu';

const Root = styled.div`
  ${Text} {
    margin-bottom: 15px;
  }
  margin-bottom: 50px;
`;

const MenuLogoutPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  return (
    <Popup
      onClosePopup={onClose}
      width={380}
      height={415}
      hPad={50}
      marginRight="60px"
      marginTop={() => '30px'}
      size="half"
      showLogo
      showFooter
    >
      <Root>
        <Menu>
          <HeaderLink to={ROUTE_TOUR}>Take a tour</HeaderLink>
          <AboutLink href={ROUTE_ABOUT}>About</AboutLink>
          <AboutLink href={ROUTE_CONTACT}>Contact</AboutLink>
          <AboutLink href={ROUTE_LEGAL}>Legal</AboutLink>
        </Menu>
        <Text size={14} transform="uppercase" align="center">
          Signed in as:
        </Text>
        <Text size={14} align="center" aria-label="Profile email">
          {profile.email}
        </Text>
        <Text align="center" aria-label="Log out button">
          <Button onClick={() => dispatch(logoutUser())}>Log out</Button>
        </Text>
      </Root>
    </Popup>
  );
};
MenuLogoutPopup.propTypes = {
  onClose: PropType.func.isRequired,
};

export default MenuLogoutPopup;
