import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropType from 'prop-types';
import styled from 'styled-components';
import { ShareAltOutlined } from '@ant-design/icons';
import {
  Text, GRAY_1, GRAY_9, ORANGE_1,
} from 'util/styled';
import { requestTinyURL } from 'services';
import { sendAnalyticsEvent } from 'util/analytics';

const Background = styled.div`
  border-radius: 4px;
  background-color: #fff;

  z-index: 999;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
`;

const ShareButton = styled(({ className, active }) => (
  <Background className={className}>
    <div>
      <ShareAltOutlined
        style={{
          color: active ? ORANGE_1 : GRAY_1,
        }}
      />
    </div>
  </Background>
))`
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  right: 10px;
  @-moz-document url-prefix() {
    right: 12px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;
  @-moz-document url-prefix() {
    width: 30px;
    height: 30px;
  }
`;

const ShareLink = styled(({ children, className }) => {
  const [copyClass, setCopyClass] = useState('not-copy');
  const ref = useRef(null);
  const { poi, visibleLayerTypes } = useSelector((state) => state.layers);
  const { downloadBy, selectedLayerTypes, selectedRegions } = useSelector(
    (state) => state.downloads,
  );
  const mapViewport = useSelector((state) => state.map.mapViewport);
  const tab = useSelector((state) => state.nav.tab);
  const exploreLayerType = useSelector((state) => state.explore.layerType);

  const selectAndCopy = () => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(ref.current);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();

    setCopyClass('copy');
    setTimeout(() => setCopyClass('not-copy'), 400);

    sendAnalyticsEvent('share_link_created', {
      tab_name: tab,
      map_origin: `${mapViewport.center[0]},${mapViewport.center[1]}`,
      selected_location: poi ? `${poi.lat},${poi.lng}` : '',
      selected_zoom_level: mapViewport.zoom,
      selected_layers: visibleLayerTypes.join(','),
      selected_download_geography_type: downloadBy,
      selected_download_geographies: selectedRegions.join(','),
      selected_download_datasets: selectedLayerTypes.join(','),
      selected_explore_dataset: exploreLayerType,
    });
  };
  return (
    <Text
      ref={ref}
      className={`${className} ${copyClass}`}
      onClick={selectAndCopy}
      size={11}
      weight="normal"
      color={GRAY_1}
    >
      {copyClass === 'copy' ? 'copied' : children}
    </Text>
  );
})`
  padding: 3px 6px;
  cursor: copy;
  width: 190px;

  &.copy {
    background-color: ${ORANGE_1};
    text-align: center;
  }

  &.not-copy {
    text-decoration: underline;
    background-color: ${GRAY_9};
    transition: background-color 200ms ease-in;
  }

  &:hover {
    font-weight: normal !important;
  }
`;

const UnstyledDrawer = ({ className }) => {
  const [shareURL, setShareURL] = useState('');
  const [open, setOpen] = useState(false);
  const exportedState = useSelector((state) => ({
    map: {
      viewport: state.map.mapViewport,
    },
    explore: {
      ...state.explore,
    },
    trends: {
      ...state.trends,
    },
    layers: {
      ...state.layers,
    },
    route: {
      ...state.route,
    },
    downloads: {
      ...state.downloads,
    },
  }));
  const stringified = JSON.stringify(exportedState);
  const [lastStringify, setLastStringify] = useState(stringified);

  useEffect(() => {
    if (shareURL !== '') return;

    requestTinyURL(exportedState)
      .then((result) => {
        setShareURL(result.data);
        setOpen(true);
      })
      .catch(() => {
        // if the URL fails, do nothing, and don't open the share.
      });
  });

  // When the exportedState would be different than what we are currently
  // displaying, close the Drawer (the viewpoint has changed or some other
  // aspect of the state has changed)
  useEffect(() => {
    if (lastStringify === stringified) return;

    setOpen(false);
    setLastStringify(stringified);
  }, [lastStringify, stringified]);

  if (!open) {
    return <div />;
  }

  return (
    <Background className={className}>
      <Text size={14} color={GRAY_1}>
        Share current map view
      </Text>
      <div className="share-row">
        <ShareAltOutlined />
        <ShareLink>{shareURL}</ShareLink>
      </div>
    </Background>
  );
};
UnstyledDrawer.propTypes = {
  className: PropType.string.isRequired,
};

const Drawer = styled(UnstyledDrawer)`
  position: absolute;
  bottom: 30px;
  right: 50px;
  padding: 5px 10px;

  .share-row {
    display: flex;
    flex-direction: row;
    margin: 5px 0;

    span {
      width: 14px;
      height: 14px;

      align-self: center;
      margin: 0 5px;
    }
  }
`;

const Share = ({ open: initialOpen }) => {
  const [open, setOpen] = useState(initialOpen);

  return (
    <div>
      <div
        aria-label="Share button"
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
        tabIndex={0}
        role="button"
      >
        <ShareButton active={open} />
      </div>
      {open && <Drawer />}
    </div>
  );
};
Share.propTypes = {
  open: PropType.bool,
};
Share.defaultProps = {
  open: false,
};

export default Share;
