import {
  SET_ROUTE,
} from 'actions';
import { ROUTE_LAYERS } from 'config/routes';

const DEFAULT_STATE = {
  route: ROUTE_LAYERS,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_ROUTE:
      return {
        route: action.payload,
      };
    default:
      return state;
  }
};
