import React from 'react';
import { useSelector } from 'react-redux';
import { Text, GRAY_1 } from 'util/styled';
import TRENDS from 'config/trends';
import styled from 'styled-components';
import Nav, { StyledSlugMessage } from '../nav';
import TrendGraph from './graph';

const Section = styled.div`
  padding: 5px 20px;
  margin-bottom: 10px;
  background: #fff;
`;

const Explore = () => {
  const trends = useSelector((state) => state.trends);

  return (
    <Nav>
      {TRENDS.map((trend) => (
        <Section key={trend.name}>
          <Text size={14} weight="600" color={GRAY_1} transform="uppercase">
            {trend.name}
          </Text>
          <TrendGraph unit={trend.unit} data={trends[trend.id]} trendName={trend.name} />
        </Section>
      ))}
      <StyledSlugMessage message="Click a point on the map to vizualize daily hazard & weather trends" />
    </Nav>
  );
};

export default Explore;
