import {
  CLEAR_POI,
  SET_POI,
  UPDATE_LAYER_POI,
  SET_LAYER_VISIBILITY,
  SET_LAYER_OPACITY,
  SET_LAYER_YEAR,
  SET_LAYER_POPUP,
  SET_DAILY_POPUP,
} from 'actions';
import { DEFAULT_YEAR, LAYERS } from 'config/layers';

const DEFAULT_OPACITY = 100;
const DEFAULT_LAYER_STATE = {
  opacity: DEFAULT_OPACITY,
  year: DEFAULT_YEAR,
};

const layersDefaultState = [];
Object.keys(LAYERS).forEach((k) => {
  layersDefaultState.push({
    ...DEFAULT_LAYER_STATE,
    visibility: LAYERS[k].visibility,
    layerType: k,
  });
});

const DEFAULT_STATE = {
  layers: layersDefaultState,
  visibleLayerTypes: Object.keys(LAYERS)
    .filter((l) => LAYERS[l].visibility)
    .map((l) => LAYERS[l].layerType),
  poi: null,
  lastUpdated: 0,
  showYearPopup: null,
  showDailyPopup: null,
};

export const findLayer = (state, layerType) => state.layers.find((l) => l.layerType === layerType);

const layerIndex = (state, layerType) => state.layers.findIndex((l) => l.layerType === layerType);
const updateLayer = (state, layerType, updateFn) => {
  const index = layerIndex(state, layerType);
  return {
    ...state,
    layers: [
      ...state.layers.slice(0, index),
      {
        ...updateFn(findLayer(state, layerType)),
      },
      ...state.layers.slice(index + 1),
    ],
    visibleLayerTypes: [...state.visibleLayerTypes],
  };
};
const updatePoi = (state, lat, lng, updateFn) => ({
  ...state,
  layers: [...state.layers],
  poi: {
    ...state.poi,
    ...updateFn(),
    lat,
    lng,
  },
  lastUpdated: new Date().getMilliseconds(),
});

export default (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  let layer;
  switch (action.type) {
    case SET_LAYER_VISIBILITY: {
      // TODO record the addition of layer visibility.
      layer = findLayer(state, action.payload.layerType);
      if (!layer) return state;

      const { visibility } = action.payload;
      const updatedState = updateLayer(
        state,
        action.payload.layerType,
        (l) => ({
          ...l,
          visibility,
        }),
      );

      if (visibility) {
        return {
          ...updatedState,
          visibleLayerTypes: [
            ...updatedState.visibleLayerTypes,
            action.payload.layerType,
          ],
        };
      }

      const index = updatedState.visibleLayerTypes.findIndex(
        (l) => l === action.payload.layerType,
      );
      return {
        ...updatedState,
        visibleLayerTypes: [
          ...updatedState.visibleLayerTypes.slice(0, index),
          ...updatedState.visibleLayerTypes.slice(index + 1),
        ],
      };
    }
    case SET_LAYER_OPACITY: {
      layer = findLayer(state, action.payload.layerType);
      if (!layer) return state;

      const { opacity } = action.payload;
      return updateLayer(state, action.payload.layerType, (l) => ({
        ...l,
        opacity,
      }));
    }
    case SET_LAYER_YEAR: {
      layer = findLayer(state, action.payload.layerType);
      if (!layer) return state;

      const { year } = action.payload;
      return updateLayer(state, action.payload.layerType, (l) => ({
        ...l,
        year,
      }));
    }
    case SET_LAYER_POPUP:
      return {
        ...state,
        showYearPopup: action.payload,
      };
    case SET_DAILY_POPUP:
      return {
        ...state,
        showDailyPopup: action.payload,
      };
    case CLEAR_POI:
      return {
        ...state,
        poi: null,
      };
    case SET_POI:
      return {
        ...state,
        poi: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };
    case UPDATE_LAYER_POI: {
      return updatePoi(state, action.payload.lat, action.payload.lng, () => ({
        [action.payload.id]: action.payload.value,
      }));
    }
    default:
      return state;
  }
};
