import { SET_VIEWPORT, SET_MAP_VIEWPORT } from 'actions';
import { MAP_LATLNG, MAP_DEFAULT_ZOOM } from 'config/mapbox';

const DEFAULT_STATE = {
  // What one can modify to change the current map viewport:
  viewport: {
    center: MAP_LATLNG,
    zoom: MAP_DEFAULT_ZOOM,
  },
  // The current map's viewport location:
  mapViewport: {
    center: MAP_LATLNG,
    zoom: MAP_DEFAULT_ZOOM,
  },
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VIEWPORT:
      return {
        ...state,
        mapViewport: { ...action.payload },
        viewport: { ...action.payload },
      };
    case SET_MAP_VIEWPORT:
      return {
        ...state,
        mapViewport: { ...action.payload },
      };
    default:
      return state;
  }
};
