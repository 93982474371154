import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import Popup from 'containers/popup';
import { Button, Text, GRAY_1 } from 'util/styled';
import styled from 'styled-components';
import { ROUTE_LAYERS } from 'config/routes';
import { updatePassword, logoutUser } from 'actions/user';
import { StyledForm, ErrorMessage } from './signin';
import { requiredPasswordFn } from './signup';

const Root = styled.div`
  margin-top: 75px;

  ${Text} {
    margin-bottom: 30px;
  }
`;

const ResetPasswordForm = ({ error, loading, onFinish }) => (
  <div>
    <Text size={14} weight="bold" transform="uppercase" color={GRAY_1}>
      Reset Your Password
    </Text>
    <StyledForm onFinish={onFinish}>
      <Form.Item name="password" rules={requiredPasswordFn('password')}>
        <Input.Password placeholder="New Password" visibilityToggle={false} />
      </Form.Item>
      <Form.Item
        name="reenter-password"
        hasFeedback
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please re-enter your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Passwords do not match');
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Re-Enter New Password"
          visibilityToggle={false}
        />
      </Form.Item>
      {error && (
        <ErrorMessage>
          Sorry, the email you entered is incorrect. Please try again.
        </ErrorMessage>
      )}
      <Form.Item>
        <Button loading={loading} htmlType="submit" full>
          Submit
        </Button>
      </Form.Item>
    </StyledForm>
  </div>
);
ResetPasswordForm.propTypes = {
  error: PropType.string.isRequired,
  loading: PropType.bool.isRequired,
  onFinish: PropType.func.isRequired,
};

const Thanks = () => (
  <div>
    <Text size={14} weight="bold" transform="uppercase" color={GRAY_1}>
      your password has been reset
    </Text>
    <Text size={12} weight="500" color={GRAY_1}>
      Your have sucessfully changed your password.
    </Text>
  </div>
);

const ResetPasswordPopup = ({ skipLogout }) => {
  // eslint-disable-next-line
  const { email, reset_token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.user.authError) !== null;
  const authInProgress = useSelector((state) => state.user.authInProgress);
  const profile = useSelector((state) => state.user.profile);

  // When you visit the ResetPasswordPopup, are you automatically logged out:
  useEffect(() => {
    if (skipLogout) return;

    dispatch(logoutUser());
  }, [dispatch, skipLogout]);

  return (
    <Popup
      width={500}
      height={profile ? 350 : 480}
      onClosePopup={() => history.push(ROUTE_LAYERS)}
      showLogo
    >
      <Root>
        {profile === null && (
          <ResetPasswordForm
            loading={authInProgress}
            onFinish={(values) => dispatch(updatePassword(email, reset_token, values.password))}
            error={error}
          />
        )}
        {profile !== null && <Thanks />}
      </Root>
    </Popup>
  );
};
ResetPasswordPopup.propTypes = {
  skipLogout: PropType.bool,
};
ResetPasswordPopup.defaultProps = {
  skipLogout: false,
};

export default ResetPasswordPopup;
