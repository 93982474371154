import {
  CLOSE_WELCOME_POPUP,
  SEEN_MOBILE_POPUP,
} from 'actions';

const DEFAULT_STATE = {
  showWelcomePopup: true,
  seenMobilePopup: false,
};

export default (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case CLOSE_WELCOME_POPUP:
      return {
        ...state,
        showWelcomePopup: false,
      };
    case SEEN_MOBILE_POPUP:
      return {
        ...state,
        seenMobilePopup: true,
      };
    default:
      return state;
  }
};
