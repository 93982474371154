import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from 'actions/user';
import { Form, Input } from 'antd';
import Popup from 'containers/popup';
import {
  Link,
  Text,
  poppinMixin,
  GRAY_1,
  GRAY_5,
  Button,
} from 'util/styled';
import { useHistory } from 'react-router-dom';
import { ROUTE_LAYERS, ROUTE_LEGAL } from 'config/routes';
import styled from 'styled-components';
import { StyledForm, ErrorMessage } from './signin';

const Root = styled.div`
  ${Text} {
    margin-bottom: 15px;
  }
`;

const Disclaimer = styled(Text)`
  margin: 35px 0 !important;

  ${Link} {
    ${poppinMixin(12, 'normal', GRAY_1)}
  }
`;

const requiredRuleFn = (placeholder) => [
  {
    required: true,
    message: `${placeholder} is required`,
  },
];

const requiredEmailRuleFn = (placeholder) => [
  {
    required: true,
    type: 'email',
    message: `${placeholder} is required`,
  },
];

export const requiredPasswordFn = () => [
  {
    required: true,
    min: 10,
    message: 'Invalid password',
  },
];

const InputWithText = styled(({
  name, className, rule, placeholder, text,
}) => (
  <div className={className}>
    <Form.Item name={name} rules={rule(placeholder)}>
      <Input placeholder={placeholder} />
    </Form.Item>
    <div className="text">{text}</div>
  </div>
))`
  position: relative;

  .text {
    ${poppinMixin(12, 'normal', GRAY_5)}
    position: absolute;
    right: 0;
    top: 40px;
  }
`;

const PasswordWithText = styled(({
  name, className, placeholder, text,
}) => (
  <div className={className}>
    <Form.Item name={name} rules={requiredPasswordFn(placeholder)}>
      <Input.Password placeholder={placeholder} visibilityToggle={false} />
    </Form.Item>
    <div className="text">{text}</div>
  </div>
))`
  position: relative;

  .text {
    ${poppinMixin(12, 'normal', GRAY_5)}
    position: absolute;
    right: 0;
    top: 40px;
  }
`;

const SignupPopup = () => {
  const history = useHistory();
  const authInProgress = useSelector((state) => state.user.authInProgress);
  const authError = useSelector((state) => state.user.authError);
  const dispatch = useDispatch();

  const attemptSignup = (values) => {
    dispatch(
      createUser(
        values.first,
        values.last,
        values.organization,
        values.email,
        values.password,
      ),
    );
  };

  return (
    <Popup
      onClosePopup={() => history.push(ROUTE_LAYERS)}
      width={575}
      height={750}
      showLogo
    >
      <Root>
        <Text size={14} weight="500">
          Create an account
        </Text>
        <StyledForm
          onFinish={attemptSignup}
          initialValues={{ remember: true }}
          full
        >
          <InputWithText
            name="first"
            placeholder="First name"
            rule={requiredRuleFn}
            text="required"
          />
          <InputWithText
            name="last"
            placeholder="Last name"
            rule={requiredRuleFn}
            text="required"
          />
          <InputWithText
            name="organization"
            placeholder="Organization name"
            rule={() => []}
            text="optional"
          />
          <InputWithText
            name="email"
            placeholder="Email address"
            rule={requiredEmailRuleFn}
            text="required"
          />
          <PasswordWithText
            name="password"
            placeholder="Password"
            text="minimum of 10 characters"
          />
          <Disclaimer size={12} align="center">
            By clicking Create Account you acknowledge you have read,
            understand, and accept the California Forest Observatory
            {' '}
            <Link to={ROUTE_LEGAL} target="_blank" rel="noreferrer noopener">terms</Link>
            .
          </Disclaimer>
          {authError && (
            <ErrorMessage title={authError}>
              Sorry, the email or password you entered is incorrect. Please try again.
            </ErrorMessage>
          )}
          <Form.Item>
            <Button loading={authInProgress} htmlType="submit" full>
              Create Account
            </Button>
          </Form.Item>
        </StyledForm>
      </Root>
    </Popup>
  );
};

export default SignupPopup;
