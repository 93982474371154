export const CREATE_USER = 'CREATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const CLOSE_REGISTERED_POPUP = 'CLOSE_REGISTERED_POPUP';
export const OPEN_LOGIN_POPUP = 'OPEN_LOGIN_POPUP';
export const CLOSE_LOGIN_POPUP = 'CLOSE_LOGIN_POPUP';
export const REFRESH_JWT = 'REFRESH_JWT';

export const SET_LAYER_VISIBILITY = 'SET_LAYER_VISIBILITY';
export const SET_LAYER_OPACITY = 'SET_LAYER_OPACITY';
export const SET_LAYER_YEAR = 'SET_LAYER_YEAR';
export const SET_LAYER_POPUP = 'SET_LAYER_POPUP';
export const SET_DAILY_POPUP = 'SET_DAILY_POPUP';

export const CLEAR_POI = 'CLEAR_POI';
export const SET_POI = 'SET_POI';
export const UPDATE_LAYER_POI = 'UPDATE_LAYER_POI';

export const UPDATE_TRENDS_POI = 'UPDATE_TRENDS_POI';

export const CLOSE_WELCOME_POPUP = 'CLOSE_WELCOME_POPUP';
export const SEEN_MOBILE_POPUP = 'SEEN_MOBILE_POPUP';

export const SET_DOWNLOAD_BY = 'SET_DOWNLOAD_BY';
export const ADD_DOWNLOAD_DATASET = 'ADD_DOWNLOAD_DATASET';
export const REMOVE_DOWNLOAD_DATASET = 'REMOVE_DOWNLOAD_DATASET';

export const ADD_REGION = 'ADD_REGION';
export const REMOVE_REGION = 'REMOVE_REGION';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';

export const OPEN_NAV = 'OPEN_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';

export const SET_EXPLORE_LAYER = 'SET_EXPLORE_LAYER';

export const SET_VIEWPORT = 'SET_VIEWPORT';
export const SET_MAP_VIEWPORT = 'SET_MAP_VIEWPORT';

export const SET_ROUTE = 'SET_ROUTE';

export const SET_TAB = 'SET_TAB';
