import { isMobile } from 'react-device-detect';

import { TODAY, THIS_YEAR } from 'util/date';
import baseHeightImg from '../images/base-height.png';
import treeHeightImg from '../images/tree-height.png';
import canopyCoverImg from '../images/canopy-cover.png';
import bulkDensityImg from '../images/bulk-density.png';
import ladderFuelsImg from '../images/ladder-fuels.png';
import wildfireHazardImg from '../images/wildfire-hazard.png';
import canopyLayerImg from '../images/canopy-layer-count.png';
import surfaceFuelsImg from '../images/surface-fuels.png';
import perimetersImg from '../images/perimeters.png';
import weatherImg from '../images/weather.png';
import config from './index';

export const YEAR_2016 = 2016;
export const YEAR_2017 = 2017;
export const YEAR_2018 = 2018;
export const YEAR_2019 = 2019;
export const YEAR_2020 = 2020;
export const DEFAULT_YEAR = YEAR_2020;

export const YEARS = [YEAR_2016, YEAR_2017, YEAR_2018, YEAR_2019, YEAR_2020];

export const LAYER_WEATHER = 'LAYER_WEATHER';
export const LAYER_PERIMETERS = 'LAYER_PERIMETERS';
export const LAYER_WILDFIRE_HAZARD = 'LAYER_WILDFIRE_HAZARD';
export const LAYER_CANOPY_BASE_HEIGHT = 'LAYER_CANOPY_BASE_HEIGHT';
export const LAYER_CANOPY_BULK_DENSITY = 'LAYER_CANOPY_BULK_DENSITY';
export const LAYER_CANOPY_COVER = 'LAYER_CANOPY_COVER';
export const LAYER_CANOPY_HEIGHT = 'LAYER_CANOPY_HEIGHT';
export const LAYER_CANOPY_LAYER_COUNT = 'LAYER_CANOPY_LAYER_COUNT';
export const LAYER_LADDER_FUEL_DENSITY = 'LAYER_LADDER_FUEL_DENSITY';
export const LAYER_SURFACE_FUELS = 'LAYER_SURFACE_FUELS';

export const STYLE_PERIMETERS = 'perimeters';
export const STYLE_SURFACEFUELS = 'surfacefuels';
export const STYLE_WILDFIRE = 'wildfire';
export const STYLE_VEGETATION = 'vegetation';
export const STYLE_WEATHER = 'weather';

const CALIFORNIA = 'California';
const FALL = 'Fall';
const SUMMER = 'Summer';
const SPRING = 'Spring';

const RES_PLANET = '00003m';
const RES_SENTINEL = '00010m';
const RES_PERIMETERS = '00500m';
const RES_WEATHER = '03000m';

export const makeAssetId = (
  layerConfig,
  year,
  geography = CALIFORNIA,
  timeOfYear = SUMMER,
  resolution = RES_SENTINEL,
) => {
  const { metric, category } = layerConfig;
  return `${geography}-${category}-${metric}-${year}-${timeOfYear}-${resolution}`;
};

export const makeCatalogAssetId = (
  layerConfig,
  year,
  geography = CALIFORNIA,
  timeOfYear = SUMMER,
  resolution = RES_SENTINEL,
  separator = ':',
) => `${layerConfig.catalog}${separator}${makeAssetId(
  layerConfig,
  year,
  geography,
  timeOfYear,
  resolution,
)}`;

export const makeWMSUrl = (layer) => {
  const p0 = layer.scale.min;
  const p100 = layer.scale.max;
  const ranges = [2, 25, 30, 50, 60, 75, 90, 98];
  const probabilities = ranges.map(
    (v) => `p${v}:${p0 + ((p100 - p0) * v) / 100}`,
  );

  return `${config.map.url}?ENV=${encodeURIComponent(
    `p0:${p0};${probabilities.join(';')};p100:${p100};nodata:-9999.0`,
  )}`;
};

export const LAYERS = {};
LAYERS[LAYER_WEATHER] = {
  name: 'Wind Speed',
  description: `The velocity and direction of pressure-driven air flow across the landscape.
  Determines the rate of fire spread, especially in grass or shrub fires.`,
  layerType: LAYER_WEATHER,
  imagePath: weatherImg,
  baseUrl: 'https://storage.googleapis.com/download/storage/v1/b/cfo-public/o/',
  scale: {
    unit: 'MPH',
    min: 0,
    max: 20,
    gradient: STYLE_WEATHER,
  },
  r2: null,
  mae: null,
  rmse: null,
  metric: 'WindVectors',
  category: 'Weather',
  resolution: '03000m',
  catalog: 'weather',
  visibility: !isMobile,
  hasPOI: false,
  colorRamp: true,
};

const makeUrl = (year, date = TODAY) => `${LAYERS[LAYER_WEATHER].baseUrl}${makeCatalogAssetId(
  LAYERS[LAYER_WEATHER],
  year,
  CALIFORNIA,
  date,
  RES_WEATHER,
  '%2F',
)}.json?alt=media`;
LAYERS[LAYER_WEATHER][YEAR_2020] = { url: makeUrl(THIS_YEAR) };

LAYERS[LAYER_PERIMETERS] = {
  name: 'Fire Hot Spots',
  description: `Active wildfire hotspots measured by VIIRS, a satellite that
  measures thermal anomalies twice daily.`,
  layerType: LAYER_PERIMETERS,
  imagePath: perimetersImg,
  baseUrl: 'https://storage.googleapis.com/download/storage/v1/b/cfo-public/o/',
  scale: {
    unit: '',
    min: 'extent',
    max: 'perimeter',
    gradient: STYLE_PERIMETERS,
  },
  r2: null,
  mae: null,
  rmse: null,
  metric: 'Perimeters',
  category: 'Wildfire',
  resolution: '00500m',
  catalog: 'wildfire',
  visibility: true,
  hasPOI: false,
  colorRamp: false,
};

const makeUrlGeojson = (year, date = TODAY) => `${LAYERS[LAYER_PERIMETERS].baseUrl}${makeCatalogAssetId(
  LAYERS[LAYER_PERIMETERS],
  year,
  CALIFORNIA,
  date,
  RES_PERIMETERS,
  '%2F',
)}.geojson?alt=media`;
LAYERS[LAYER_PERIMETERS][YEAR_2020] = { url: makeUrlGeojson(THIS_YEAR) };

LAYERS[LAYER_WILDFIRE_HAZARD] = {
  name: 'Wildfire Hazard',
  description: `A composite metric of burn probability and intensity. Generated
  by simulating thousands of fires, forecasting their spread and synthesizing
  the results.`,
  layerType: LAYER_WILDFIRE_HAZARD,
  imagePath: wildfireHazardImg,
  scale: {
    unit: 'unitless',
    min: 0,
    max: 300,
    gradient: STYLE_WILDFIRE,
  },
  r2: null,
  mae: null,
  rmse: null,
  metric: 'Hazard',
  category: 'Wildfire',
  resolution: '00150m',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_WILDFIRE_HAZARD][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_WILDFIRE_HAZARD], YEAR_2016, CALIFORNIA, FALL),
  format: 'image/png8',
  styles: 'wildfire',
};
LAYERS[LAYER_WILDFIRE_HAZARD][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_WILDFIRE_HAZARD], YEAR_2017, CALIFORNIA, FALL),
  format: 'image/png8',
  styles: 'wildfire',
};
LAYERS[LAYER_WILDFIRE_HAZARD][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_WILDFIRE_HAZARD], YEAR_2018, CALIFORNIA, FALL),
  format: 'image/png8',
  styles: 'wildfire',
};
LAYERS[LAYER_WILDFIRE_HAZARD][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_WILDFIRE_HAZARD], YEAR_2019, CALIFORNIA, FALL),
  format: 'image/png8',
  styles: 'wildfire',
};
LAYERS[LAYER_WILDFIRE_HAZARD][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_WILDFIRE_HAZARD], YEAR_2020, CALIFORNIA, FALL),
  format: 'image/png8',
  styles: 'wildfire',
};

LAYERS[LAYER_CANOPY_BASE_HEIGHT] = {
  name: 'Canopy Base Height',
  description: `The distance between the ground and the lowest branches in the
  canopy. Predicts whether a surface fire transitions to a canopy fire.`,
  layerType: LAYER_CANOPY_BASE_HEIGHT,
  imagePath: baseHeightImg,
  scale: {
    unit: 'meters (m)',
    min: 0,
    max: 4,
    gradient: STYLE_VEGETATION,
  },
  r2: 0.66,
  mae: 0.51,
  rmse: 0.93,
  metric: 'CanopyBaseHeight',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_CANOPY_BASE_HEIGHT][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BASE_HEIGHT], YEAR_2016, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BASE_HEIGHT][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BASE_HEIGHT], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BASE_HEIGHT][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BASE_HEIGHT], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BASE_HEIGHT][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BASE_HEIGHT], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BASE_HEIGHT][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BASE_HEIGHT], YEAR_2020, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_CANOPY_BULK_DENSITY] = {
  name: 'Canopy Bulk Density',
  description: `The mass of available fuel that burns in a canopy fire—typically
  the leaves and small branches— divided by the volume of the crown.`,
  layerType: LAYER_CANOPY_BULK_DENSITY,
  imagePath: bulkDensityImg,
  scale: {
    unit: 'kg/m3',
    min: 0,
    max: 0.10,
    gradient: STYLE_VEGETATION,
  },
  r2: null,
  mae: null,
  rmse: null,
  metric: 'CanopyBulkDensity',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_CANOPY_BULK_DENSITY][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BULK_DENSITY], YEAR_2016, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BULK_DENSITY][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BULK_DENSITY], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BULK_DENSITY][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BULK_DENSITY], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BULK_DENSITY][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BULK_DENSITY], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_BULK_DENSITY][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_BULK_DENSITY], YEAR_2020, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_CANOPY_COVER] = {
  name: 'Canopy Cover',
  description: `The horizontal cover fraction occupied by tree canopies. Maps
  community type & fire regime, as well as available habitat for tree-dwelling
  species.`,
  layerType: LAYER_CANOPY_COVER,
  imagePath: canopyCoverImg,
  scale: {
    unit: 'percent (%)',
    min: 0,
    max: 90,
    gradient: STYLE_VEGETATION,
  },
  r2: 0.92,
  mae: 5.24,
  rmse: 8.68,
  metric: 'CanopyCover',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_CANOPY_COVER][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_COVER], YEAR_2016, CALIFORNIA, FALL, RES_PLANET),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_COVER][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_COVER], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_COVER][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_COVER], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_COVER][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_COVER], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_COVER][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_COVER], YEAR_2020, CALIFORNIA, SPRING, RES_PLANET),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_CANOPY_HEIGHT] = {
  name: 'Canopy Height',
  description: `The distance between the ground and the top of the canopy.
  Canopy height is an indicator of aboveground biomass and the amount of foliage
  that may be consumed in a crown fire.`,
  layerType: LAYER_CANOPY_HEIGHT,
  imagePath: treeHeightImg,
  scale: {
    unit: 'meters (m)',
    min: 0,
    max: 25,
    gradient: STYLE_VEGETATION,
  },
  r2: 0.89,
  mae: 1.56,
  rmse: 2.86,
  metric: 'CanopyHeight',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: true,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_CANOPY_HEIGHT][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_HEIGHT], YEAR_2016, CALIFORNIA, FALL, RES_PLANET),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_HEIGHT][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_HEIGHT], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_HEIGHT][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_HEIGHT], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_HEIGHT][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_HEIGHT], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_HEIGHT][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_HEIGHT], YEAR_2020, CALIFORNIA, FALL, RES_PLANET),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_CANOPY_LAYER_COUNT] = {
  name: 'Canopy Layer Count',
  description: `The number of distinct vertical canopy layers. Vertical layer
  count is a proxy for leaf area index, and maps canopy complexity.`,
  layerType: LAYER_CANOPY_LAYER_COUNT,
  imagePath: canopyLayerImg,
  scale: {
    unit: 'count (#)',
    min: 0,
    max: 3,
    gradient: STYLE_VEGETATION,
  },
  r2: 0.59,
  mae: 0.45,
  rmse: 0.67,
  metric: 'CanopyLayerCount',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_CANOPY_LAYER_COUNT][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_LAYER_COUNT], YEAR_2016, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_LAYER_COUNT][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_LAYER_COUNT], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_LAYER_COUNT][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_LAYER_COUNT], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_LAYER_COUNT][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_LAYER_COUNT], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_CANOPY_LAYER_COUNT][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_CANOPY_LAYER_COUNT], YEAR_2020, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_LADDER_FUEL_DENSITY] = {
  name: 'Ladder Fuel Density',
  description: `The amount of surface / understory fuels. Maps how low-lying
  surface fires can transition to canopy fires.`,
  layerType: LAYER_LADDER_FUEL_DENSITY,
  imagePath: ladderFuelsImg,
  scale: {
    unit: 'percent (%)',
    min: 0,
    max: 50,
    gradient: STYLE_VEGETATION,
  },
  r2: 0.71,
  mae: 3.9,
  rmse: 7.1,
  metric: 'LadderFuelDensity',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_LADDER_FUEL_DENSITY][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_LADDER_FUEL_DENSITY], YEAR_2016, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_LADDER_FUEL_DENSITY][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_LADDER_FUEL_DENSITY], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_LADDER_FUEL_DENSITY][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_LADDER_FUEL_DENSITY], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_LADDER_FUEL_DENSITY][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_LADDER_FUEL_DENSITY], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};
LAYERS[LAYER_LADDER_FUEL_DENSITY][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_LADDER_FUEL_DENSITY], YEAR_2020, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_VEGETATION,
};

LAYERS[LAYER_SURFACE_FUELS] = {
  name: 'Surface Fuels',
  description: `A model describing vegetation fuel type, size class, depth, moisture content
  and heat content. Determines fire behavior patterns like flame length and spread rate.`,
  layerType: LAYER_SURFACE_FUELS,
  imagePath: surfaceFuelsImg,
  scale: {
    unit: 'Spread Rate',
    min: 'LOW',
    max: 'HIGH',
    gradient: STYLE_SURFACEFUELS,
  },
  r2: null,
  mae: null,
  rmse: null,
  metric: 'SurfaceFuels',
  category: 'Vegetation',
  catalog: 'cfo',
  visibility: false,
  hasPOI: true,
  colorRamp: true,
};
LAYERS[LAYER_SURFACE_FUELS][YEAR_2016] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_SURFACE_FUELS], YEAR_2016, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_SURFACEFUELS,
};
LAYERS[LAYER_SURFACE_FUELS][YEAR_2017] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_SURFACE_FUELS], YEAR_2017, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_SURFACEFUELS,
};
LAYERS[LAYER_SURFACE_FUELS][YEAR_2018] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_SURFACE_FUELS], YEAR_2018, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_SURFACEFUELS,
};
LAYERS[LAYER_SURFACE_FUELS][YEAR_2019] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_SURFACE_FUELS], YEAR_2019, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_SURFACEFUELS,
};
LAYERS[LAYER_SURFACE_FUELS][YEAR_2020] = {
  layers: makeCatalogAssetId(LAYERS[LAYER_SURFACE_FUELS], YEAR_2020, CALIFORNIA, SUMMER),
  format: 'image/png8',
  styles: STYLE_SURFACEFUELS,
};

export const DAILY_LAYERS = [LAYERS[LAYER_WEATHER], LAYERS[LAYER_PERIMETERS]];

export const ANNUAL_LAYERS = [
  // LAYERS[LAYER_WILDFIRE_HAZARD],
  LAYERS[LAYER_CANOPY_COVER],
  LAYERS[LAYER_CANOPY_HEIGHT],
  LAYERS[LAYER_CANOPY_BASE_HEIGHT],
  LAYERS[LAYER_CANOPY_BULK_DENSITY],
  LAYERS[LAYER_CANOPY_LAYER_COUNT],
  LAYERS[LAYER_LADDER_FUEL_DENSITY],
  LAYERS[LAYER_SURFACE_FUELS],
];

export const BASE_DOWNLOAD_LAYER = LAYERS[LAYER_CANOPY_HEIGHT];
