import React, { useState, useRef, useEffect } from 'react';
import PropType from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { WMSTileLayer, GeoJSON } from 'react-leaflet';
import * as topojson from 'topojson-client';
import { REGIONS } from 'config/downloads';
import { BASE_DOWNLOAD_LAYER, DEFAULT_YEAR, makeWMSUrl } from 'config/layers';
import { addRegion, removeRegion } from 'actions/downloads';
import { BLACK, ORANGE_1 } from 'util/styled';

// Cache HTTP requests so they aren't refetched whenever the layer is changed.
const cachedLayers = {};

const TopoJSON = ({ data, style, onClick }) => {
  const layerRef = useRef(null);

  const addData = (layer, jsonData) => {
    layer.clearLayers();
    if (jsonData.type === 'Topology') {
      Object.keys(jsonData.objects).forEach((obj) => {
        const geojson = topojson.feature(jsonData, obj);
        layer.addData(geojson);
      });
    } else {
      layer.addData(jsonData);
    }
  };

  useEffect(() => {
    const layer = layerRef.current.leafletElement;
    addData(layer, data);
  }, [data]);

  return (
    <GeoJSON
      ref={layerRef}
      style={style}
      onClick={onClick}
      onMouseOver={(e) => {
        const { layer } = e;
        // To access the true map bounds we must use _map:
        // eslint-disable-next-line
        if (e.target._map.getBounds().contains(layer.getBounds())) {
          layer.openPopup();
        }
      }}
      onBlur={(e) => e.layer.closePopup()}
      onMouseOut={(e) => e.layer.closePopup()}
      onFocus={(e) => e.layer.openPopup()}
      onEachFeature={(feature, layer) => {
        layer.bindPopup(`<div>${feature.properties.Name}</div>`, { className: 'hoverPopup' });
      }}
    />
  );
};
TopoJSON.propTypes = {
  data: PropType.shape({}).isRequired,
  style: PropType.func.isRequired,
  onClick: PropType.func.isRequired,
};

const CountiesData = () => {
  const [data, setData] = useState(null);
  const downloadBy = useSelector((state) => state.downloads.downloadBy);
  const selectedRegions = useSelector((state) => state.downloads.selectedRegions);
  const dispatch = useDispatch();
  const region = REGIONS.find((r) => r.name === downloadBy);

  useEffect(() => {
    if (region.url in cachedLayers) {
      setData(cachedLayers[region.url]);
      return;
    }

    fetch(region.url)
      .then((response) => response.json())
      .then((d) => {
        cachedLayers[region.url] = d;
        setData(d);
      });
  }, [region.url]);

  if (data === null) {
    return <div />;
  }

  const mapStyle = (feature) => {
    const selected = selectedRegions.includes(feature.properties.Name);
    const fillOpacity = selected ? 0.6 : 0.15;

    return {
      fillColor: selected ? ORANGE_1 : BLACK,
      fillOpacity,
      color: BLACK,
      weight: 1.5,
    };
  };

  return (
    <div>
      <WMSTileLayer
        layers={BASE_DOWNLOAD_LAYER[DEFAULT_YEAR].layers}
        format={BASE_DOWNLOAD_LAYER[DEFAULT_YEAR].format}
        styles={BASE_DOWNLOAD_LAYER[DEFAULT_YEAR].styles}
        url={makeWMSUrl(BASE_DOWNLOAD_LAYER)}
        opacity={100}
        transparent
      />
      <TopoJSON
        key={downloadBy}
        data={data}
        style={mapStyle}
        onClick={(e) => {
          if (selectedRegions.includes(e.layer.feature.properties.Name)) {
            dispatch(removeRegion(e.layer.feature.properties.Name));
          } else {
            dispatch(addRegion(e.layer.feature.properties.Name));
          }
        }}
      />
    </div>
  );
};

export default CountiesData;
