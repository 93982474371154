import React from 'react';
import styled from 'styled-components';
import oneImg from '../containers/app/tour-popup/images/tour-popup-1-fig.png';
import twoImg from '../containers/app/tour-popup/images/tour-popup-2-fig.png';
import threeImg from '../containers/app/tour-popup/images/tour-popup-3-fig.png';
import fourImg from '../containers/app/tour-popup/images/tour-popup-fig-4.png';
import fiveImg from '../containers/app/tour-popup/images/tour-popup-fig-5.png';
import visibilityHiddenImg from '../containers/app/navigation/layers/layer/images/layer-visibility-icon-hidden.png';
import helpIconImg from '../containers/app/navigation/layers/layer/images/layer-help-icon.png';
import shareIconImg from '../containers/app/share/images/share-icon.png';

import { ExternalLink, ExternalLinkUnderlined } from '../util/styled';

const Eye = styled.div`
  display: inline-block;
  background-image: url(${visibilityHiddenImg});
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: center;
  width: 19px;
  height: 12px;
`;

const Info = styled.div`
  display: inline-block;
  background-image: url(${helpIconImg});
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  width: 12px;
  height: 12px;
`;

const Share = styled.div`
  display: inline-block;
  background-image: url(${shareIconImg});
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center bottom;
  width: 14px;
  height: 14px;
`;

const WHY_NAVIGATION = {
  imagePath: oneImg,
  title: 'Why does California need a Forest Observatory?',
  body: [
    `For decades, land managers and wildfire scientists have relied on coarse
    information on the vegetation fuels that drive wildfire hazard.`,
    `Using high resolution satellite imagery from Planet, airborne laser
    scanning and artificial intelligence, we created a system to provide current
    data at scale of the individual tree.`,
    `We aim to support the development of data-driven land management
    to decrease wildfire hazard and increase forest resilience statewide.`,
  ],
};

const HAZARD_NAVIGATION = {
  imagePath: twoImg,
  title: null,
  body: [
    `The Forest Observatory provides unprecedented visibility into
    the drivers of wildfire behavior—vegetation fuels, weather, and topography.`,
    <div>
      Using the
      {' '}
      <strong>Layers</strong>
      {' '}
      tab, you can activate any dataset by clicking its name or on
      {' '}
      <Eye />
      . To view more details, or
      visualize historical data, click
      {' '}
      <Info />
      .
    </div>,
    `Activate the Wind Speed layer to see how current weather conditions interact
    with vegetation fuels around active Hot Spots.`,
    <div>
      Use the
      {' '}
      <Share />
      {' '}
      button to create a URL capturing your current map view that
      you can share with others.
    </div>,
  ],
};

const PYREGENCE_NAVIGATION = {
  imagePath: threeImg,
  imageLink: 'https://pyregence.org',
  title: null,
  body: [
    <div>
      Separately, we provide a near-term wildfire exposure forecast, using
      outputs from the
      {' '}
      <ExternalLinkUnderlined href="https://pyregence.org/" size={12} target="_blank">
        Pyregence
      </ExternalLinkUnderlined>
      {' '}
      consortium. Exposure is the wildfire hazard that a specific location like
      a community or forested watershed might experience.
    </div>,
    `Pyregence computes short-term wildfire exposure scores using California Forest
    Observatory vegetation fuels and weather forecasts to predict upcoming hazards.`,
    <div>
      Find this in the
      {' '}
      <strong>Trends</strong>
      {' '}
      tab, where you can click on a map
      location to show the recent history and 3-day forecast of weather and
      wildfire exposure for your community or other place of interest.
    </div>,
  ],
};

const DOWNLOAD_NAVIGATION = {
  imagePath: fourImg,
  title: null,
  reverse: true,
  body: [
    `All Forest Observatory data are free for scientists, government agencies,
  and non-profits to use for non-commercial purposes.`,
    <div>
      Using the
      {' '}
      <strong>Download</strong>
      {' '}
      tab, you can select multiple data
      layers for the current year and subset them by county, community, or
      watershed. Click each area of interest to activate/deactivate before
      submitting your download request.
    </div>,
    <div>
      Need to download the entire state and/or historical data? Use the
      {' '}
      <ExternalLinkUnderlined
        size={12}
        href="https://github.com/forestobservatory/cfo-api"
      >
        Forest Observatory API
      </ExternalLinkUnderlined>
      .
    </div>,
    <div>
      For commercial licensing please contact
      {' '}
      <ExternalLink size={12} href="mailto:info@forestobservatory.com">
        info@forestobservatory.com
      </ExternalLink>
    </div>,
  ],
};

const EXPLORE_NAVIGATION = {
  imagePath: fiveImg,
  title: null,
  reverse: true,
  body: [
    `California is rapidly changing—rising temperatures and deepening
  drought are increasing wildfire frequency and severity. Paired with an
  unnatural accumulation of vegetation fuels, we find ourselves facing a
  dangerous future.`,
    <div>
      The
      {' '}
      <strong>Explore</strong>
      {' '}
      tab offers curated views of the types of
      changes the Forest Observatory can detect.
    </div>,
    <div>
      In addition to regular updates of current data, new layers—like tree
      mortality—are coming soon.
    </div>,
    <div>
      Create an account to access data and stay up-to-date on all Forest
      Observatory activity.
    </div>,
  ],
};

const TOURS = [
  WHY_NAVIGATION,
  HAZARD_NAVIGATION,
  PYREGENCE_NAVIGATION,
  DOWNLOAD_NAVIGATION,
  EXPLORE_NAVIGATION,
];

export default TOURS;
