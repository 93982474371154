import React from 'react';
import PropType from 'prop-types';
import { Select } from 'antd';
import styled from 'styled-components';
import {
  poppinMixin, GRAY_5, ORANGE_1, GRAY_12,
} from 'util/styled';

const { Option } = Select;

const Combobox = ({
  className, options, defaultValue, onChange,
}) => (
  <Select
    defaultValue={defaultValue}
    onChange={onChange}
    className={className}
    dropdownClassName={className}
  >
    {options.map((option) => (
      <Option key={option} value={option} className={className}>
        {option}
      </Option>
    ))}
  </Select>
);
Combobox.propTypes = {
  className: PropType.string.isRequired,
  options: PropType.arrayOf(PropType.string).isRequired,
  defaultValue: PropType.string.isRequired,
  onChange: PropType.func.isRequired,
};

const StyledCombobox = styled(Combobox)`
  text-transform: uppercase;
  text-align: center;

  .ant-select-selector {
    border-radius: 3px !important;
    ${poppinMixin(11, '600', GRAY_5)}
  }

  .ant-select-selector .ant-select-item-option-content {
    border-color: white !important;
  }

  .ant-select-item-option-selected {
    background-color: white !important;
  }

  .ant-select-item-option-selected .ant-select-item-option-content {
    background-color: ${GRAY_12};
    color: ${ORANGE_1} !important;
  }

  .ant-select-item-option-content {
    ${poppinMixin(11, '600', GRAY_5)}
    border: 1px solid rgba(112, 112, 112, 0.1);
    bordor-radius: 3px;
  }
`;

export default StyledCombobox;
