import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GRAY_10 } from 'util/styled';
import { openNav, closeNav } from 'actions/nav';
import { useHistory } from 'react-router-dom';
import {
  ROUTE_EXPLORE,
  ROUTE_LAYERS,
  ROUTE_DOWNLOAD,
  ROUTE_TRENDS,
} from 'config/routes';
import {
  TAB_LAYERS,
  TAB_TRENDS,
  TAB_DOWNLOAD,
  TAB_EXPLORE,
} from 'reducers/nav';
import TabMenu from './tab-menu';
import Explore from './explore';
import Layers from './layers';
import Trends from './trends';
import Download from './download';
import collapseImg from './images/navigation-collapse-button-arrow.png';
import { setViewport } from '../../../actions/map';
import { MAP_DEFAULT_ZOOM, MAP_LATLNG } from '../../../config/mapbox';

const Root = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 294px;
  height: 100%;
  background-color: ${GRAY_10};
  z-index: 999;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
`;

const Header = styled.div`
  height: 83px;
  padding: 12px 16px 12px 16px;
  background-color: #fff;
`;

const CollapseButton = styled.div`
  position: absolute;
  top: 55px;
  right: -20px;
  width: 20px;
  height: 44px;
  background-color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  box-shadow: 3px 1px 4px rgba(0, 0, 0, 0.25);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-image: url(${collapseImg});
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: center;
  }
  
  ${({ collapsed }) => (collapsed ? `
  left: 0;
  z-index: 9999;
  &::before {
    transform: rotate(-180deg);
  }
  ` : null)}
`;

const TabContent = styled.div`
  height: calc(100% - 139px);
`;

const Navigation = () => {
  const tab = useSelector((state) => state.nav.tab);
  const dispatch = useDispatch();
  const history = useHistory();

  const collapsed = !useSelector((state) => state.nav.open);

  const onCollapseSelected = (e) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;
    dispatch(closeNav());
  };

  const onExpandSelected = (e) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;
    dispatch(openNav());
  };

  let tabComponent;
  if (tab === TAB_LAYERS) {
    tabComponent = <Layers />;
  } else if (tab === TAB_EXPLORE) {
    tabComponent = <Explore />;
  } else if (tab === TAB_TRENDS) {
    tabComponent = <Trends />;
  } else if (tab === TAB_DOWNLOAD) {
    tabComponent = <Download />;
  }

  if (collapsed) {
    return (
      <CollapseButton
        role="button"
        aria-label="Expand Navigation"
        tabIndex={0}
        collapsed={collapsed}
        onClick={onExpandSelected}
        onKeyPress={onExpandSelected}
      />
    );
  }

  const onTabNavigate = (route) => {
    if (tab === TAB_EXPLORE && route !== TAB_EXPLORE) {
      dispatch(
        setViewport({
          center: MAP_LATLNG,
          zoom: MAP_DEFAULT_ZOOM,
        }),
      );
    }

    history.push(route);
  };

  return (
    <Root aria-label="Left Navigation">
      <CollapseButton
        role="button"
        aria-label="Collapse Navigation"
        tabIndex={0}
        onClick={onCollapseSelected}
        onKeyPress={onCollapseSelected}
      />
      <Header>
        <TabMenu
          onLayersSelected={() => onTabNavigate(ROUTE_LAYERS)}
          onTrendsSelected={() => onTabNavigate(ROUTE_TRENDS)}
          onDownloadSelected={() => onTabNavigate(ROUTE_DOWNLOAD)}
          onExploreSelected={() => onTabNavigate(ROUTE_EXPLORE)}
        />
      </Header>
      <TabContent>{tabComponent}</TabContent>
    </Root>
  );
};

export default Navigation;
