import React from 'react';
import { useDispatch } from 'react-redux';
import { seenMobilePopup } from 'actions/welcome';
import Popup from 'containers/popup';
import { ExternalLink, Text, GRAY_1 } from 'util/styled';
import styled from 'styled-components';

const Body = styled(Text)`
  margin-bottom: 10px;
`;

const Root = styled.div`
  ${Body}:last-of-type {
    margin-bottom: 25px;
  }
`;

const MobilePopup = () => {
  const dispatch = useDispatch();

  return (
    <Popup
      onClosePopup={() => {
        dispatch(seenMobilePopup());
      }}
      showLogo
      showFooter
      size="half"
      width={325}
      height={425}
      hPad={25}
    >
      <Root>
        <Body size={12} color={GRAY_1}>
          Welcome to the California Forest Observatory, a data-driven forest
          monitoring system that maps the drivers of wildfire hazard from space.
        </Body>
        <Body size={12} color={GRAY_1}>
          By using the Forest Observatory you agree to the
          <ExternalLink size={12} href="/legal.html"> terms of use</ExternalLink>
          .
        </Body>
        <Body size={12} color={GRAY_1}>
          This
          site is not optimized for mobile devices. Visit the Forest Observatory
          on your laptop, desktop or tablet for the best experience.
        </Body>
        <Body size={12} color={GRAY_1}>
          <strong>This application is best experienced in landscape orientation.</strong>
        </Body>
      </Root>
    </Popup>
  );
};

export default MobilePopup;
