import {
  LOGIN_USER,
  CREATE_USER,
  LOGOUT_USER,
  REFRESH_JWT,
  OPEN_LOGIN_POPUP,
  CLOSE_LOGIN_POPUP,
  AUTH_FAILED,
  AUTH_IN_PROGRESS,
  CLOSE_REGISTERED_POPUP,
} from 'actions';

const DEFAULT_STATE = {
  profile: null,
  authInProgress: false,
  authError: null,
  showRegisteredPopup: false,
  showLoginPopup: false,
};

export default (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case CREATE_USER:
      return {
        ...DEFAULT_STATE,
        profile: action.payload,
        showRegisteredPopup: true,
      };
    case LOGIN_USER:
      return {
        ...DEFAULT_STATE,
        profile: action.payload,
      };
    case REFRESH_JWT:
      return {
        ...DEFAULT_STATE,
        profile: {
          ...state.profile,
          token: action.payload.token,
        },
      };
    case AUTH_IN_PROGRESS:
      return {
        ...DEFAULT_STATE,
        authInProgress: true,
        profile: null,
      };
    case AUTH_FAILED:
      return {
        ...DEFAULT_STATE,
        authError: action.payload,
        profile: null,
      };
    case LOGOUT_USER:
      return DEFAULT_STATE;
    case CLOSE_REGISTERED_POPUP:
      return {
        ...state,
        showRegisteredPopup: false,
      };
    case OPEN_LOGIN_POPUP:
      return {
        ...state,
        showLoginPopup: true,
      };
    case CLOSE_LOGIN_POPUP:
      return {
        ...state,
        showLoginPopup: false,
      };
    default:
      return state;
  }
};
