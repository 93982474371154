import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Slider } from 'antd';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  setLayerVisibility,
  setLayerOpacity,
  setLayerPopup,
  setDailyPopup,
} from 'actions/layers';
import { Text, GRAY_1 } from 'util/styled';
import styled from 'styled-components';
import {
  LAYERS,
  STYLE_PERIMETERS,
  STYLE_SURFACEFUELS,
  STYLE_WILDFIRE,
  STYLE_VEGETATION,
  STYLE_WEATHER,
} from 'config/layers';
import windImg from './images/palette-wind.png';
import perimetersImg from './images/palette-perimeters.png';
import hazardImg from './images/palette-hazard.png';
import vegetationImg from './images/palette-vegetation.png';
import surfaceFuelsImg from './images/palette-surfacefuels.png';
import visibilityHiddenImg from './images/layer-visibility-icon-hidden.png';
import visibilityVisibleImg from './images/layer-visibility-icon-visible.png';
import layerHelpIconImg from './images/layer-help-icon.png';
import layerHelpIconEnabledImg from './images/layer-help-icon-enabled.png';

const Row = styled.div`
  width: 100%;
  position: relative;

  :nth-child(2) {
    margin-top: 15px;
  }

  :nth-child(4) {
    margin-top: 7px;
  }
`;

const Root = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: #fff;
  margin-bottom: 12px;
  user-select: none;
`;

export const Gradient = styled(({
  ariaLabel, className, layer, scale,
}) => {
  const dispatch = useDispatch();
  const { layerType, opacity } = layer;
  const {
    min, max, gradient, unit,
  } = scale;

  return (
    <Row aria-label={ariaLabel} className={className}>
      <div className={`anyGradient ${gradient}`} />
      <Text className="label" size={10} transform="uppercase">
        {unit}
      </Text>
      <Text className="min" size={10} transform="uppercase">
        {min}
      </Text>
      <Text className="max" size={10} transform="uppercase">
        {max}
      </Text>
      <OpacitySlider
        className="slider"
        defaultValue={100}
        max={100}
        min={0}
        value={opacity}
        color={GRAY_1}
        onChange={(newOpacity) => dispatch(setLayerOpacity(layerType, newOpacity))}
      />
      <OpacityLabel className="slider-label" size={10} transform="uppercase">
        Opacity
      </OpacityLabel>
    </Row>
  );
})`
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;
    
    .anyGradient {
      flex-basis: 130px;
    }
    
    .label {
      flex-basis: 80px;
    }
    
    .min {
      position: absolute;
      left: 0;
      top: 15px;
    }
    
    .max {   
      flex-basis: 130px;
      margin-right: 10px;
      text-align: right;
    }
    
    .slider-label {
      flex-grow: 1;
    }
    
    .slider {
      width: 130px;
    }
  }
  
  display: grid;
  grid-template-columns: 65px 65px 1fr;
  grid-template-areas:
    "gradient gradient label"
    "min      max      ."
    "slider   slider   slider-label";
  overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: max-height 400ms ease-in;
  max-height: ${({ visible }) => (visible ? '400' : '0')}px;

  .anyGradient {
    grid-area: gradient;

    display: inline-block;
    vertical-align: top;
    width: 130px;
    height: 10px;
    margin: 2px 0px 0px 0px;
    background-image: url(${({ scale }) => (scale.gradient === STYLE_WEATHER && windImg)
      || (scale.gradient === STYLE_PERIMETERS && perimetersImg)
      || (scale.gradient === STYLE_SURFACEFUELS && surfaceFuelsImg)
      || (scale.gradient === STYLE_WILDFIRE && hazardImg)
      || (scale.gradient === STYLE_VEGETATION && vegetationImg)});
    background-repeat: no-repeat;
    background-size: 144px;
    background-position: center;
  }

  .label {
    grid-area: label;

    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }

  .min {
    grid-area: min;
  }

  .max {
    grid-area: max;
    text-align: end;
  }

  .slider {
    grid-area: slider;
    margin: 10px 0px;

    .ant-slider-track {
      background-color: ${GRAY_1};
      height: 3px;
    }

    .ant-slider-handle {
      background-color: ${GRAY_1};
      border: none;
      height: 11px;
      width: 11px;
      margin-top: -4px;
    }

    &:hover {
      .ant-slider-track {
        background-color: ${GRAY_1};
      }
    }
  }

  .slider-label {
    grid-area: slider-label;
    align-self: center;
    margin-left: 10px;
  }
`;

const VisibilityButton = styled.button`
  position: absolute;
  top: 7px;
  right: 18px;
  width: 19px;
  height: 12px;
  border: none;
  background-color: transparent;
  background-image: url(${(props) => (props.selected ? visibilityVisibleImg : visibilityHiddenImg)});
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: center;
  cursor: pointer;
`;

const ClickableTitle = styled.div`
  ${Text} {
    display: inline-block;
    vertical-align: top;
    padding-top: 2px;
    cursor: pointer;
  }
`;

const HelpButton = styled.button`
  position: absolute;
  top: 7px;
  right: 0px;
  width: 12px;
  height: 15px;
  border: none;
  background-color: transparent;
  background-image: url(${(props) => (props.enabled ? layerHelpIconEnabledImg : layerHelpIconImg)});
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  cursor: pointer;
`;

const OpacitySlider = styled(Slider)`
  display: inline-block;
  vertical-align: top;
  margin: 1px 10px 0px 0px;
  width: 130px;
`;

const OpacityLabel = styled(Text)`
  display: inline-block;
  vertical-align: top;
`;

const Layer = ({
  ariaLabel, name, layerType, showHelp, showDailyHelp, isAnnual,
}) => {
  const dispatch = useDispatch();
  const showYearPopup = useSelector((state) => state.layers.showYearPopup);
  const showDailyPopup = useSelector((state) => state.layers.showDailyPopup);
  const layers = useSelector((state) => state.layers.layers);

  const layer = layers.find((l) => l.layerType === layerType);
  // eslint-disable-next-line
  const { visibility, year } = layer;
  const { scale } = LAYERS[layerType];

  const onVisibilityToggle = (e) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;

    dispatch(setLayerVisibility(layerType, !visibility));
  };

  return (
    <Root aria-label={ariaLabel} unselected={!visibility}>
      <Row>
        <ClickableTitle
          aria-label={`${name} title${visibility ? ' visible' : ''}`}
          onClick={onVisibilityToggle}
          onKeyDown={onVisibilityToggle}
          role="button"
          tabIndex="0"
        >
          <Text size={14} transform="uppercase">
            {visibility && isAnnual ? `${name} (${year})` : name}
          </Text>
          <VisibilityButton
            selected={visibility}
            aria-label={`show ${name}`}
            tabIndex={0}
            type="button"
          />
        </ClickableTitle>
        {showHelp && !isMobile && (
          <HelpButton
            enabled={layerType === showYearPopup}
            type="button"
            aria-label={`info ${name}`}
            tabIndex={0}
            onClick={() => dispatch(setLayerPopup(layerType))}
            onKeyDown={() => dispatch(setLayerPopup(layerType))}
          />
        )}
        {showDailyHelp && !isMobile && (
          <HelpButton
            enabled={layerType === showDailyPopup}
            type="button"
            aria-label={`show ${name}`}
            tabIndex={0}
            onClick={() => dispatch(setDailyPopup(layerType))}
            onKeyDown={() => dispatch(setDailyPopup(layerType))}
          />
        )}
      </Row>
      <Gradient layer={layer} visible={visibility} scale={scale} />
    </Root>
  );
};
Layer.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  layerType: PropTypes.string.isRequired,
  showHelp: PropTypes.bool,
  showDailyHelp: PropTypes.bool,
  isAnnual: PropTypes.bool,
};
Layer.defaultProps = {
  showHelp: true,
  showDailyHelp: false,
  ariaLabel: '',
  isAnnual: false,
};

export default Layer;
