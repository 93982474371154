import React, { useEffect, useState, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { ROUTE_LAYERS } from 'config/routes';
import { MAP_LATLNG, MAP_DEFAULT_ZOOM } from 'config/mapbox';
import PropType from 'prop-types';
import { setViewport } from 'actions/map';
import styled from 'styled-components';
import {
  poppinMixin, Link, GRAY_1, GRAY_9,
} from 'util/styled';
import { requestPOISearch } from 'services';
import { CloseCircleFilled } from '@ant-design/icons';
import { sendAnalyticsEvent } from 'util/analytics';

const Root = styled.div`
  display: flex;
  flex-direction: column;

  justify-items: center;
  align-items: center;
  padding: 0 10px;

  position: absolute;
  top: 30px;
  right: 50px;
  width: 180px;
  border-radius: 4px;
  background-color: #fff;

  z-index: 999;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
`;

const Input = styled.input`
  ${poppinMixin(13, '700', GRAY_1)}
  border: none;
  margin: 5px 5px 6px 0;
  padding: 0;
  width: 100%;

  ::placeholder {
    ${poppinMixin(12)}
  }

  :focus {
    outline: none;
  }
`;

const SearchAndClose = styled.div`
  display: flex;
  width: 100%;

  .closeButton {
    height: 23px;
    align-self: center;
    cursor: pointer;
    @-moz-document url-prefix() {
      margin-left: -15px;
    }
  }
`;

const SelectableLink = styled(Link)`
  margin: 0 -10px;
  padding: 5px 10px;
  background-color: ${({ selected }) => (selected ? GRAY_9 : '#fff')};
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Search = ({ search: initialSearch, results: initialResults }) => {
  const [results, setResults] = useState(initialResults);
  const [search, setSearch] = useState(initialSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    if (search.length === 0) {
      setResults({ features: [] });
      return;
    }

    requestPOISearch(search)
      .then((response) => {
        setResults(response.data);
      })
      .catch(() => {
        setResults({ features: [] });
      });
  }, [search]);

  const resetSearch = () => setSearch('');

  const selectResultFn = (result) => async () => {
    // first zoom out, otherwise leaflet will sometimes not pan
    // correctly.
    await dispatch(
      setViewport({
        center: MAP_LATLNG,
        zoom: MAP_DEFAULT_ZOOM,
      }),
    );

    dispatch(
      setViewport({
        center: [result.center[1], result.center[0]],
        zoom: 12,
      }),
    );

    setResults({ features: [] });
    sendAnalyticsEvent('search_poi_selected', { poi_name: result.text });
  };

  const [selectedResult, updateSelectedResult] = useReducer(
    (state, action) => {
      switch (action.action) {
        case 'Set':
          return { index: action.index };
        case 'Reset':
          return { index: -1 };
        case 'Enter':
          if (results.features.length === 0) return state;

          if (state.index === -1) {
            selectResultFn(results.features[0])();
            return { index: 0 };
          }

          selectResultFn(results.features[state.index])();
          return state;
        case 'ArrowUp':
          if (state.index < 0) {
            return { index: -1 };
          }
          return { index: state.index - 1 };
        case 'ArrowDown':
          if (state.index >= results.features.length - 1) {
            return { index: results.features.length - 1 };
          }
          return { index: state.index + 1 };
        default:
          return state;
      }
    },
    { index: -1 },
  );

  return (
    <Root>
      <SearchAndClose>
        <Input
          aria-label="Search California input"
          type="text"
          placeholder="Search California"
          value={search}
          onChange={(v) => setSearch(v.target.value)}
          onKeyDown={(e) => updateSelectedResult({ action: e.key })}
          onBlur={() => updateSelectedResult({ action: 'Reset' })}
        />
        {search.length > 0 && (
          <div
            className="closeButton"
            role="button"
            tabIndex={0}
            onClick={resetSearch}
            onKeyDown={resetSearch}
          >
            <CloseCircleFilled />
          </div>
        )}
      </SearchAndClose>
      <Results>
        {results.features.map((result, index) => (
          <SelectableLink
            key={result.id}
            size={12}
            selected={selectedResult.index === index}
            to={ROUTE_LAYERS}
            onClick={(e) => {
              selectResultFn(result)();
              e.preventDefault();
            }}
            onMouseOver={() => updateSelectedResult({ action: 'Set', index })}
          >
            {result.text}
          </SelectableLink>
        ))}
      </Results>
    </Root>
  );
};
Search.propTypes = {
  search: PropType.string,
  results: PropType.shape({
    features: PropType.arrayOf(PropType.any),
  }),
};
Search.defaultProps = {
  search: '',
  results: { features: [] },
};

export default Search;
