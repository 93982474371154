import React from 'react';
import { Form, Input } from 'antd';
import {
  poppinMixin,
  GRAY_1,
  GRAY_8,
  ORANGE_1,
  Button,
  Link,
  Text,
} from 'util/styled';
import { useHistory } from 'react-router-dom';
import PropType from 'prop-types';
import styled from 'styled-components';
import { ROUTE_SIGNUP, ROUTE_RESET_PASSWORD } from 'config/routes';

export const StyledForm = styled(
  ({
    children, className, onFinish, onFinishFailed,
  }) => (
    <Form
      className={className}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {children}
    </Form>
  ),
)`
  .ant-input,
  .ant-input-password {
    ${(props) => poppinMixin(props.full ? 20 : 12, 'normal', GRAY_1)}
    border: none;
    border-bottom: 1px solid ${GRAY_8};
    padding-left: 0;
    box-shadow: none;

    &:focus,
    &:hover {
      box-shadow: none;
      border-bottom: 1px solid ${GRAY_8};
    }
  }

  .ant-form-item-has-error .ant-input {
    &,
    &:focus,
    &:hover {
      border-color: ${GRAY_8} !important;
      box-shadow: none;
    }
  }

  .ant-form-item-has-success .ant-form-item-explain {
    text-align: right;
  }

  .ant-form-item-has-error .ant-form-item-explain {
    ${poppinMixin(12, '600', ORANGE_1)}
  }

  .ant-form-item-control {
    margin-bottom: 5px;
  }

  .ant-form-item-control-input-content input {
    padding-left: 0;
  }

  .ant-form-item-control-input-content {
    text-align: center;
  }

  .ant-form-item-children-icon {
    display: none;
  }

  .reset-password {
    position: absolute;
    right: 0;
    top: -30px;
    z-index: 2;
  }
`;

export const ErrorMessage = styled.div`
  color: ${ORANGE_1};
  text-align: center;
  margin-bottom: 10px;
`;

export const ForgotPasswordLink = styled.div`
  position: relative;
`;

const SignInForm = ({
  authError,
  authInProgress,
  onFinish,
  onSignup,
  size,
}) => {
  const history = useHistory();
  return (
    <StyledForm
      full={size === 'full'}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Form.Item
        name="email"
        onFinish={onFinish}
        rules={[
          {
            required: true,
            type: 'email',
            message: 'Email address',
          },
        ]}
      >
        <Input placeholder="email" aria-label="Email input" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Password',
          },
        ]}
      >
        <Input.Password aria-label="Password input" placeholder="password" visibilityToggle={false} />
      </Form.Item>
      <ForgotPasswordLink>
        <Link
          size={size === 'half' ? 12 : 14}
          className="reset-password"
          weight="normal"
          to={ROUTE_RESET_PASSWORD}
          aria-label="Forgot Password link"
        >
          Forgot Password
        </Link>
      </ForgotPasswordLink>
      {authError && (
        <ErrorMessage title={authError} aria-label="Login error message">
          Sorry, the email or password you entered is incorrect. Please try
          again.
        </ErrorMessage>
      )}
      <Form.Item>
        <Button
          loading={authInProgress}
          htmlType="submit"
          full={size === 'full'}
        >
          Sign In
        </Button>
      </Form.Item>
      <Text size={12} color={GRAY_1} weight="500" align="center">
        First time here?&nbsp;&nbsp;
        <Link
          to={ROUTE_SIGNUP}
          onClick={() => {
            onSignup();
            history.push(ROUTE_SIGNUP);
          }}
        >
          Create an account
        </Link>
      </Text>
    </StyledForm>
  );
};
SignInForm.propTypes = {
  authError: PropType.string.isRequired,
  authInProgress: PropType.bool.isRequired,
  onFinish: PropType.func.isRequired,
  onSignup: PropType.func,
  size: PropType.oneOf(['full', 'half']),
};
SignInForm.defaultProps = {
  size: 'full',
  onSignup: () => {},
};

export default SignInForm;
