import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { ExternalLink, Text } from 'util/styled';
import closeButton from './images/popup-close-button.png';
import welcomeLogoImg from './images/welcome-popup-logo.png';
import vibrantPlanetImg from './images/vibrant-planet.png';
import mooreImg from './images/moore.png';
import planetImg from './images/planet.png';
import saloSciencesImg from './images/salo-sciences.png';

const RootDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const Logo = styled.div`
  width: ${(props) => (props.full ? 200 : 150)}px;
  height: ${(props) => (props.full ? 70 : 51)}px;
  background-image: url(${welcomeLogoImg});
  background-repeat: no-repeat;
  background-size: ${(props) => (props.full ? 200 : 150)}px;
  background-position: center;
  margin-bottom: 15px;
`;

const WindowDiv = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  height: min(calc(100vh - 10px), ${(props) => props.height}px);
  background-color: #fff;
  border-radius: 10px;
  margin: ${(props) => props.marginTop(props)} ${(props) => props.marginRight} 0px ${(props) => props.marginLeft};
  padding: 30px ${(props) => props.hPad}px 0px ${(props) => props.hPad}px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  
  // Edge
  @supports (-ms-ime-align:auto) {
    -ms-overflow-style: none;
    margin: ${(props) => {
    const marginTop = props.marginTop(props);
    const marginTopIE = marginTop.match(/max.+?,(.+?)\)$/i);

    return (`
      ${marginTopIE && marginTopIE.length ? marginTopIE[1] : (marginTop || `calc(30% - ${props.height / 2}px)`)}
      ${props.marginRight || 'auto'}
      0px
      ${props.marginLeft || 'auto'}
    `);
  }}
  }
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-overflow-style: none;
    margin: ${(props) => {
    const marginTop = props.marginTop(props);
    const marginTopIE = marginTop.match(/max.+?,(.+?)\)$/i);

    return (`
      ${marginTopIE && marginTopIE.length ? marginTopIE[1] : (marginTop || `calc(30% - ${props.height / 2}px)`)}
      ${props.marginRight || 'auto'}
      0px
      ${props.marginLeft || 'auto'}
    `);
  }}
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-image: url(${closeButton});
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
`;

const ContentDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const LogosLabel = styled(Text)`
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const UnstyledLogos = ({ className }) => (
  <div className={className}>
    <ExternalLink href="https://salo.ai"><img src={saloSciencesImg} alt="Salo Sciences" /></ExternalLink>
    <ExternalLink href="https://www.planet.com"><img src={planetImg} alt="Planet" /></ExternalLink>
    <ExternalLink href="https://www.vibrantplanet.net"><img src={vibrantPlanetImg} alt="Vibrant Planet" /></ExternalLink>
    <ExternalLink href="https://www.moore.org"><img src={mooreImg} alt="Moore" /></ExternalLink>
  </div>
);
UnstyledLogos.propTypes = {
  className: PropType.string.isRequired,
};

const Logos = styled(UnstyledLogos)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: ${(props) => (props.full ? 52 : 26)}px;
  }
`;

const Popup = ({
  className,
  children,
  hPad,
  width,
  height,
  marginLeft,
  marginRight,
  marginTop,
  onClosePopup,
  showLogo,
  showFooter,
  size,
}) => (
  <RootDiv>
    <WindowDiv
      width={width}
      height={height}
      hPad={hPad}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
    >
      <CloseButton
        aria-label="close Popup"
        role="button"
        tabIndex={0}
        onClick={onClosePopup}
      />
      <ContentDiv className={className}>
        {showLogo && <Logo full={size === 'full'} />}
        {children}
        {showFooter && <LogosLabel full={size === 'full'}>Developed By:</LogosLabel>}
        {showFooter && <Logos full={size === 'full'} />}
      </ContentDiv>
    </WindowDiv>
  </RootDiv>
);

Popup.propTypes = {
  className: PropType.string,
  children: PropType.node.isRequired,
  onClosePopup: PropType.func.isRequired,
  width: PropType.number,
  height: PropType.number,
  hPad: PropType.number,
  marginLeft: PropType.string,
  marginRight: PropType.string,
  marginTop: PropType.func,
  showLogo: PropType.bool,
  showFooter: PropType.bool,
  size: PropType.oneOf(['full', 'half']),
};

Popup.defaultProps = {
  className: '',
  width: 720,
  height: 480,
  hPad: 100,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: (props) => `max(5px, calc(50vh - ${props.height / 2}px))`,
  showLogo: false,
  showFooter: false,
  size: 'full',
};

export default Popup;
