export const ROUTE_LAYERS = '/';
export const ROUTE_TRENDS = '/trends';
export const ROUTE_DOWNLOAD = '/download';
export const ROUTE_EXPLORE = '/explore';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_UPDATE_PASSWORD = '/reset-password/:reset_token/:email';
export const ROUTE_SHARE = '/share/:id';

export const ROUTE_TOUR = '/tour';
export const ROUTE_ABOUT = '/about.html#about';
export const ROUTE_CONTACT = '/about.html#contact';
export const ROUTE_LEGAL = '/legal.html';
