import { Steps } from 'antd';
import styled from 'styled-components';
import { poppinMixin, GRAY_1, ORANGE_1 } from 'util/styled';

const { Step } = Steps;

export const StyledSteps = styled(Steps)`
  width: 60% !important;
  margin: 0 auto 15px auto !important;

  :not(.ant-steps-item-active) .ant-steps-icon-dot {
    width: 11px !important;
    height: 11px !important;
    color: ${GRAY_1};
    background: ${GRAY_1} !important;
  }

  :not(.ant-steps-item-active) .ant-steps-item-title {
    ${poppinMixin(11, 'bold')}
  }

  :not(.ant-steps-item-active) .ant-steps-item-container:hover .ant-steps-item-title {
    ${poppinMixin(11, 'bold')}
  }

  .ant-steps-item-active .ant-steps-icon-dot {
    width: 11px !important;
    height: 11px !important;
    color: ${ORANGE_1};
    background: ${ORANGE_1} !important;
    border: solid 1px ${GRAY_1};
    margin-top: 1px;
  }

  .ant-steps-item-active .ant-steps-item-title {
    ${poppinMixin(11, 'bold', ORANGE_1)}
  }

  .ant-steps-item-active .ant-steps-item-container:hover .ant-steps-item-title {
    ${poppinMixin(11, 'bold', ORANGE_1)}
  }
`;

export const StyledStep = styled(Step)`
  .ant-steps-item-tail {
    width: 100% !important;
    margin: 2px 0 0 15px !important;
  }

  .ant-steps-item-tail::after {
    width: 100% !important;
    height: 2px !important;
  }

  .ant-steps-item-tail::after {
    background-color: ${GRAY_1} !important;
  }

  .ant-steps-item-content {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 20px !important;
  }

  .ant-steps-item-icon {
    width: 11px !important;
    height: 11px !important;
    margin-left: 20px !important;
    width: 20px !important;
  }

  .ant-steps-item-container {
    margin-left: 0px !important;
  }
`;

export default StyledSteps;
