const pad = (num) => num.toString().padStart(2, '0');

export const monthDay = (month, day) => `${pad(month)}${pad(day)}`;
export const yearMonthDay = (date) => `${date.getFullYear()}-${monthDay(date.getMonth() + 1, date.getDate())}`;
export const TODAY = monthDay(new Date().getMonth() + 1, new Date().getDate());
export const THIS_YEAR = new Date().getFullYear();

export const dateDiff = (dayOffset, hours = 0) => {
  const date = new Date();
  date.setHours(hours, 0, 0, 0);
  date.setDate(date.getDate() + dayOffset);
  return date;
};

export const addHours = (date, hours) => {
  const result = new Date();
  result.setDate(date.getDate());
  result.setHours(date.getHours() + hours);
  return result;
};

export const dateFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
