import React from 'react';
import { useDispatch } from 'react-redux';
import { closeRegisteredPopup } from 'actions/user';
import Popup from 'containers/popup';
import { Text, GRAY_1 } from 'util/styled';
import styled from 'styled-components';

const Root = styled.div`
  margin-top: 75px;

  ${Text} {
    margin-bottom: 30px;
  }
`;

const RegisteredPopup = () => {
  const dispatch = useDispatch();

  return (
    <Popup
      width={575}
      height={390}
      onClosePopup={() => dispatch(closeRegisteredPopup())}
      showLogo
    >
      <Root>
        <Text size={16} align="center">
          Welcome to the California Forest Observatory.
        </Text>
        <Text size={16} weight="bold" color={GRAY_1} align="center">
          You have successfully signed in.
        </Text>
      </Root>
    </Popup>
  );
};

export default RegisteredPopup;
