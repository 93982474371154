import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GRAY_1 } from 'util/styled';
import MenuLoginPopup from 'containers/app/login-popup/menu';
import MenuLogoutPopup from 'containers/app/login-popup/menulogout';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 10px;
  width: 31px;
  height: 31px;
  border-radius: 4px;
  background-color: #fff;
  z-index: 999;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const Hamburger = styled.div`
  height: 2px;
  width: 16px;
  background: ${GRAY_1};
  &::after {
    content: '';
    display: block;
    border-top: 2px solid ${GRAY_1};
    margin-top: -14px;
  }
  &::before {
    content: '';
    display: block;
    border-bottom: 2px solid ${GRAY_1};
    margin-top: 6px;
  }
`;

const Menu = () => {
  const [showMenu, setShowmenu] = useState(false);
  const profile = useSelector((state) => state.user.profile);

  return (
    <div>
      <Root role="button" aria-label="Show Menu" tabIndex={0} onClick={() => setShowmenu(true)}>
        <Hamburger onClick={() => setShowmenu(true)} />
      </Root>
      {showMenu && !profile && <MenuLoginPopup onClose={() => setShowmenu(false)} />}
      {showMenu && profile && <MenuLogoutPopup onClose={() => setShowmenu(false)} />}
    </div>
  );
};

export default Menu;
