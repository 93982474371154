import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import SignupPopup from 'containers/app/login-popup/signup';
import MobilePopup from 'containers/app/mobile-popup';
import RegisteredPopup from 'containers/app/login-popup/registered';
import LoginPopup from 'containers/app/login-popup';
import ResetPasswordPopup from 'containers/app/login-popup/reset-password';
import ResetPasswordRequestPopup from 'containers/app/login-popup/reset-password-request';
import { setTab } from 'actions/nav';
import { setRoute } from 'actions/route';
import { refreshUserToken } from 'actions/user';
import {
  ROUTE_ABOUT,
  ROUTE_CONTACT,
  ROUTE_DOWNLOAD,
  ROUTE_TRENDS,
  ROUTE_EXPLORE,
  ROUTE_LAYERS,
  ROUTE_LEGAL,
  ROUTE_RESET_PASSWORD,
  ROUTE_UPDATE_PASSWORD,
  ROUTE_SIGNUP,
  ROUTE_SHARE,
  ROUTE_TOUR,
} from 'config/routes';
import {
  TAB_LAYERS,
  TAB_EXPLORE,
  TAB_TRENDS,
  TAB_DOWNLOAD,
} from 'reducers/nav';
import Menu from './menu';
import Search from './search';
import Share from './share';
import Navigation from './navigation';
import { Map } from './map';
import WelcomePopup from './welcome-popup';

const TABLET_WIDTH = 768;

const Root = styled.div`
  height: 100%;
  width: 100vw;
`;

const MapPage = () => (
  <Root>
    <Menu />
    <Search />
    <Share />
    <Map />
    <Navigation />
  </Root>
);

const AppSwitch = () => {
  const showWelcomePopup = useSelector(
    (state) => state.welcome.showWelcomePopup,
  );
  const tab = useSelector((state) => state.nav.tab);
  const seenMobilePopup = useSelector((state) => state.welcome.seenMobilePopup);
  const lastRoute = useSelector((state) => state.route.route);
  const profile = useSelector((state) => state.user.profile);
  const showRegisteredPopup = useSelector(
    (state) => state.user.showRegisteredPopup,
  );
  const showLoginPopup = useSelector((state) => state.user.showLoginPopup);
  const [checkedJWTRefresh, setCheckedJWTRefresh] = useState(false);
  const hasProfile = profile !== null;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const MAP_ROUTES = [
    { path: ROUTE_DOWNLOAD, tab: TAB_DOWNLOAD },
    { path: ROUTE_TRENDS, tab: TAB_TRENDS },
    { path: ROUTE_EXPLORE, tab: TAB_EXPLORE },
    { path: ROUTE_LAYERS, tab: TAB_LAYERS },
  ];

  useEffect(() => {
    if (!location) return;
    if (location.pathname.startsWith('/share/')) return;
    if (location.pathname.startsWith(ROUTE_TOUR)) return;

    dispatch(setRoute(location.pathname));

    const lastMapRoute = MAP_ROUTES.find((m) => m.path === lastRoute);
    const lastTab = lastMapRoute ? lastMapRoute.tab : TAB_LAYERS;

    if (tab !== lastTab) {
      dispatch(setTab(lastTab));
    }
  }, [dispatch, location, lastRoute, MAP_ROUTES, tab]);

  useEffect(() => {
    if (checkedJWTRefresh) return;
    setCheckedJWTRefresh(true);

    if (!profile) return;

    dispatch(refreshUserToken(profile.token));
  }, [dispatch, profile, checkedJWTRefresh]);

  const showMobilePopup = !seenMobilePopup && window.screen.width < TABLET_WIDTH;

  if (showMobilePopup) {
    return (
      <div>
        <MapPage />
        <MobilePopup />
      </div>
    );
  }

  return (
    <Switch>
      <Route path={ROUTE_SHARE}>
        <Redirect to={lastRoute} />
      </Route>
      <Route path={ROUTE_UPDATE_PASSWORD}>
        <ResetPasswordPopup />
      </Route>
      <Route path={ROUTE_RESET_PASSWORD}>
        <ResetPasswordRequestPopup />
      </Route>
      <Route path={ROUTE_SIGNUP}>
        {hasProfile && <Redirect to={ROUTE_LAYERS} />}
        <SignupPopup />
      </Route>
      <Route path={ROUTE_ABOUT}>{ROUTE_ABOUT}</Route>
      <Route path={ROUTE_CONTACT}>{ROUTE_CONTACT}</Route>
      <Route path={ROUTE_LEGAL}>{ROUTE_LEGAL}</Route>
      <Route path={ROUTE_TOUR}>
        <WelcomePopup onClose={() => history.push(lastRoute)} />
      </Route>
      {MAP_ROUTES.map(({ path }) => (
        <Route key={path} path={path}>
          {showWelcomePopup && !isMobile && <Redirect to={ROUTE_TOUR} />}
          {showRegisteredPopup && <RegisteredPopup />}
          {showLoginPopup && <LoginPopup />}
        </Route>
      ))}
    </Switch>
  );
};

const ROUTES_WITH_MAP_PAGE = [
  ROUTE_DOWNLOAD,
  ROUTE_TRENDS,
  ROUTE_EXPLORE,
  ROUTE_LAYERS,
  ROUTE_UPDATE_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_SIGNUP,
  ROUTE_TOUR,
];

const App = () => (
  <Router>
    <AppSwitch />
    <Route path={`:path(${ROUTES_WITH_MAP_PAGE.join('|')})`}>
      <MapPage />
    </Route>
  </Router>
);

export default App;
