import React, { useState } from 'react';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';
import { closeWelcomePopup } from 'actions/welcome';
import Popup from 'containers/popup';
import TourPopup from 'containers/app/tour-popup';
import {
  Button, ExternalLinkUnderlined, Text, GRAY_1,
} from 'util/styled';
import styled from 'styled-components';

const TourButton = styled(Button)`
  display: inherit !important;
  margin: 15px auto;
  padding-top: 11px;
  height: min-content !important;
`;

const Body = styled(Text)`
  margin-bottom: 10px;
`;

const WelcomePopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const [showTour, setShowTour] = useState(false);

  if (showTour) {
    return <TourPopup onClose={onClose} />;
  }

  return (
    <Popup
      onClosePopup={() => {
        dispatch(closeWelcomePopup());
        onClose();
      }}
      showLogo
      showFooter
    >
      <Body size={12} color={GRAY_1}>
        Welcome to the California Forest Observatory, a data-driven forest
        monitoring system. We map the drivers of wildfire hazard across the
        state—including forest structure, weather, topography &amp;
        infrastructure—from space.
      </Body>
      <Body size={12} color={GRAY_1}>
        Our regularly-updated, tree-level data is designed to be used by
        researchers, landowners &amp; policymakers. The data provided here is free and
        available for non-commercial research purposes by scientists, governments,
        and non-profits.
      </Body>
      <Body size={12} color={GRAY_1}>
        Before using the site, please review the
        {' '}
        <ExternalLinkUnderlined size={12} href="/legal.html">Terms of Use</ExternalLinkUnderlined>
        {' '}
        and the
        {' '}
        <ExternalLinkUnderlined size={12} href="/privacy.html">Privacy Policy</ExternalLinkUnderlined>
        .
      </Body>
      <Body size={12} color={GRAY_1}>
        By closing this window and using this site you acknowledge
        you have read, understand, and accept the California Forest Observatory
        {' '}
        <ExternalLinkUnderlined size={12} href="/legal.html">Terms of Use</ExternalLinkUnderlined>
        .
      </Body>
      <TourButton onClick={() => setShowTour(true)}>TAKE A TOUR</TourButton>
    </Popup>
  );
};
WelcomePopup.propTypes = {
  onClose: PropType.func,
};
WelcomePopup.defaultProps = {
  onClose: () => {},
};

export default WelcomePopup;
