import React from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';
import { poppinMixin, GRAY_1, GRAY_10 } from 'util/styled';
import bananaSlugImg from './images/helpful-banana-slug.png';

const Root = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-top: 12px;
  text-align: left;
`;

const SlugMessage = ({ children, className, message }) => (
  <div className={className}>
    {children}
    <div className="slug">
      <div className="text">{message}</div>
      <div className="icon" />
    </div>
  </div>
);
SlugMessage.propTypes = {
  children: PropType.node,
  className: PropType.string.isRequired,
  message: PropType.string.isRequired,
};
SlugMessage.defaultProps = {
  children: null,
};

export const StyledSlugMessage = styled(SlugMessage)`
  position: absolute;
  bottom: 0px;
  padding: 10px;
  background-color: ${GRAY_10};
  display: flex;
  flex-direction: column;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.05);
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
    
    button {
      align-self: center;
    }
  }

  .slug {
    display: flex;
    flex-direction: row;

    .text {
      flex-grow: 1;
      vertical-align: top;
      margin-right: 5px;
      ${poppinMixin(12, 'normal', GRAY_1)}
      text-align: left;
    }

    .icon {
      vertical-align: top;
      width: 100px;
      height: 37px;
      background-image: url(${bananaSlugImg});
      background-repeat: no-repeat;
      background-size: 49px;
    }
  }
`;

const Nav = ({ children, className }) => (
  <Root className={className}>
    <Content>{children}</Content>
  </Root>
);
Nav.propTypes = {
  children: PropType.node.isRequired,
  className: PropType.string,
};
Nav.defaultProps = {
  className: '',
};

export default Nav;
