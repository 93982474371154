import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setExploreLayer from 'actions/explore';
import { setViewport } from 'actions/map';
import { findLayer } from 'reducers/layers';
import styled from 'styled-components';
import { EXPLORATIONS } from 'config/explore';
import { LAYERS } from 'config/layers';
import {
  Text, GRAY_1, GRAY_9, ORANGE_1,
} from 'util/styled';
import Nav, { StyledSlugMessage } from '../nav';
import { Dataset } from '../download/datasets';
import { Gradient } from '../layers/layer';

const TriSwitch = styled(({ className, ariaLabel }) => (
  <div aria-label={ariaLabel} className={className}>
    <Text size={12} weight="bold" className="pre">
      PRE
    </Text>
    <div className="switch">
      <div className="knob" />
    </div>
    <Text size={12} weight="bold" className="post">
      POST
    </Text>
  </div>
))`
  display: flex;
  margin-top: 20px;

  .switch {
    position: relative;
    width: 42px;
    height: 18px;
    border-radius: 10px;
    margin: 0 5px;
    background-color: ${({ state }) => (state === undefined ? '#fff' : ORANGE_1)};
    border: solid 0.5px
      ${({ state }) => (state === undefined ? GRAY_9 : 'transparent')};

    .knob {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      left: ${({ state }) => {
    if (state === undefined) {
      return 11;
    }

    if (state) {
      return 42 - 16 - 2;
    }

    return 0;
  }}px;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
      border: solid 0.5px
        ${({ state }) => (state === undefined ? GRAY_9 : 'transparent')};
      background-color: #fff;
      border-radius: 50%;
      transition: left 200ms ease-out;
    }
  }

  .pre {
    color: ${({ state }) => (state || state === undefined ? GRAY_1 : ORANGE_1)} !important;
  }

  .post {
    color: ${({ state }) => (state ? ORANGE_1 : GRAY_1)} !important;
  }
`;

const ButtonContainer = styled.div`
  border: 1px solid ${({ bordered }) => (bordered ? ORANGE_1 : 'transparent')};
  border-radius: 5px;
  padding: 10px;
  margin: 8px;
`;

const NameYear = styled.div`
  margin-bottom: 10px;

  ${Text} {
    display: inline;
    margin-right: 10px;
  }
`;

export const Section = styled(
  ({
    className, explore, layer, isPost, onClick,
  }) => (
    <div className={className}>
      <ButtonContainer bordered={isPost !== undefined}>
        <div
          className="summary-and-image"
          role="button"
          tabIndex={0}
          aria-label={`Toggle ${explore.name}`}
          onClick={onClick}
          onKeyPress={onClick}
        >
          <div className="summary">
            <h2>
              <Text
                size={12}
                weight="bold"
                color={isPost !== undefined ? ORANGE_1 : GRAY_1}
              >
                {explore.name}
              </Text>
            </h2>
            <Text size={12} color={GRAY_1}>
              {explore.description}
            </Text>
            <TriSwitch state={isPost} ariaLabel={`pre post switch ${LAYERS[layer.layerType].name}`} />
          </div>
          <Dataset
            layerConfig={LAYERS[layer.layerType]}
            selected={false}
            onChange={() => {}}
          />
        </div>
        {isPost !== undefined && (
        <NameYear aria-label={`name year ${LAYERS[layer.layerType].name}`}>
          <Text size={14} weight="600" color={GRAY_1}>
            {LAYERS[layer.layerType].name}
          </Text>
          <Text>
            (
            {isPost ? explore.post.year : explore.pre.year}
            )
          </Text>
        </NameYear>
        )}
        <Gradient
          layer={layer}
          visible={isPost !== undefined}
          scale={LAYERS[layer.layerType].scale}
        />
      </ButtonContainer>
    </div>
  ),
)`
  background-color: #fff;
  padding: 1px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;

  .summary-and-image {
    display: flex;

    .summary {
      flex-grow: 1;
    }

    img {
      border-color: transparent;
    }

    &:focus {
      outline: none;
    }
  }
`;

const Explore = () => {
  const [updatedViewpont, setUpdatedViewpont] = useState(false);

  const layers = useSelector((state) => state.layers);
  const activeExplore = useSelector((state) => state.explore);
  const { isPost, layerType } = activeExplore;
  const dispatch = useDispatch();

  if (!updatedViewpont) {
    const exploreConfig = EXPLORATIONS.find(
      (e) => e.layerType === activeExplore.layerType,
    );
    setUpdatedViewpont(true);
    setTimeout(() => dispatch(setViewport(exploreConfig)), 1000);
  }

  return (
    <Nav>
      {EXPLORATIONS.map((explore) => (
        <Section
          key={explore.name}
          explore={explore}
          layer={findLayer(layers, explore.layerType)}
          isPost={explore.layerType === layerType ? isPost : undefined}
          onClick={() => {
            let newIsPost = false;
            if (explore.layerType === layerType) {
              newIsPost = !isPost;
            }

            dispatch(setExploreLayer(explore.layerType, newIsPost));
            dispatch(setViewport(explore));
          }}
        />
      ))}
      <StyledSlugMessage message="Click the PRE / POST toggle to show the change between years." />
    </Nav>
  );
};

export default Explore;
