import { SET_VIEWPORT, SET_MAP_VIEWPORT } from 'actions';

export const setMapViewport = (viewport) => ({
  type: SET_MAP_VIEWPORT,
  payload: { ...viewport },
});

export const setViewport = (viewport) => ({
  type: SET_VIEWPORT,
  payload: { ...viewport },
});
