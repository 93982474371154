import {
  SET_DOWNLOAD_BY,
  ADD_DOWNLOAD_DATASET,
  REMOVE_DOWNLOAD_DATASET,
  ADD_REGION,
  REMOVE_REGION,
  CLEAR_SELECTIONS,
} from 'actions';

export const setDownloadBy = (option) => ({
  type: SET_DOWNLOAD_BY,
  payload: option,
});

export const addDownloadDataset = (layerType) => ({
  type: ADD_DOWNLOAD_DATASET,
  payload: layerType,
});

export const removeDownloadDataset = (layerType) => ({
  type: REMOVE_DOWNLOAD_DATASET,
  payload: layerType,
});

export const addRegion = (region) => ({
  type: ADD_REGION,
  payload: region,
});

export const removeRegion = (region) => ({
  type: REMOVE_REGION,
  payload: region,
});

export const clearSelections = () => ({
  type: CLEAR_SELECTIONS,
});
