import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { clearPointOfInterest } from 'actions/layers';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import snakeImg from './images/snake-pin.png';

const snakeIcon = new L.Icon({
  iconUrl: snakeImg,
  iconAnchor: [14, 60],
  iconSize: [32, 60],
});

const DataPointTrends = ({ poi }) => {
  const dispatch = useDispatch();

  return (poi && (
    <Marker
      icon={snakeIcon}
      position={[poi.lat, poi.lng]}
      onClick={() => dispatch(clearPointOfInterest())}
    />
  ));
};

DataPointTrends.propTypes = {
  poi: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
};

export default DataPointTrends;
