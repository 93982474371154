/* eslint-disable max-classes-per-file, class-methods-use-this */
import Locate from 'leaflet.locatecontrol';

import { withLeaflet, MapControl } from 'react-leaflet';

class LocateControl extends MapControl {
  componentDidMount() {
    const startsDirectly = true;

    this.leafletElement.addTo(this.props.leaflet.map); // from MapControl

    if (startsDirectly) {
      this.leafletElement.start();
    }
  }

  createLeafletElement() {
    const locateOptions = {
      position: 'bottomright',
      setView: false,
      flyTo: true,
      keepCurrentZoomLevel: true,
      cacheLocation: true,
      drawCircle: true,
      drawMarker: true,
      onActivate: () => {}, // callback before engine starts retrieving locations
    };

    return new Locate(locateOptions);
  }
}

export default withLeaflet(LocateControl);
