import React from 'react';
import PropType from 'prop-types';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Button as AntdButton } from 'antd';

export const BLACK = '#000000';
export const GRAY_1 = '#707070';
export const GRAY_2 = '#7C7C7C';
export const GRAY_5 = '#AAAAAA';
export const GRAY_8 = '#D2D2D2';
export const GRAY_9 = '#E5E5E5';
export const GRAY_10 = '#F7F7F7';
export const GRAY_12 = '#F1F1F1';

export const ORANGE_1 = '#F4A740';

export const GREEN_1 = '#134F3A';

export const poppinMixin = (size, weight = 'normal', color = GRAY_1) => css`
  font-family: Poppins, sans-serif;
  font-size: ${size}px !important;
  font-weight: ${weight} !important;
  color: ${color} !important;
`;

export const Text = styled.div`
  ${(props) => poppinMixin(props.size, props.weight, props.color)}
  text-transform: ${(props) => props.transform};
  text-align: ${(props) => props.align};
`;
Text.propTypes = {
  size: PropType.number,
  weight: PropType.string,
  color: PropType.string,
  align: PropType.string,
};
Text.defaultProps = {
  size: 11,
  weight: 'normal',
  color: GRAY_5,
  transform: 'none',
  align: 'left',
};

export const Link = styled(RouterLink)`
  ${(props) => poppinMixin(props.size || 14, props.weight || '600', props.color)}

  &:hover {
    font-weight: ${(props) => props.weight || '600'} !important;
    color: ${(props) => props.color || GREEN_1} !important;
  }
`;

export const ExternalLink = styled(({ children, className, href }) => (
  <a
    className={className}
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    {children}
  </a>
))`
  ${(props) => poppinMixin(props.size || 14, props.weight || '600')}
  &:hover {
    font-weight: bold !important;
    color: ${GREEN_1} !important;
  }
`;

export const ExternalLinkUnderlined = styled(({ children, className, href }) => (
  <a
    className={className}
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    {children}
  </a>
))`
  ${(props) => poppinMixin(props.size || 14, props.weight || '600')}
  text-decoration: underline;
  &:hover {
    font-weight: bold !important;
    color: ${GREEN_1} !important;
    text-decoration: underline;
  }
`;

export const Button = styled(
  ({
    className, children, onClick, htmlType, type, loading,
  }) => (
    <AntdButton
      className={className}
      type={type}
      htmlType={htmlType}
      onClick={onClick}
      loading={loading}
    >
      {children}
    </AntdButton>
  ),
)`
  ${(props) => poppinMixin(props.full ? 16 : 12, '500', '#fff')}
  background: ${GREEN_1} !important;
  border-color: ${GREEN_1} !important;
  border-radius: 3px !important;

  padding: ${(props) => (props.full ? 15 : 10)}px ${(props) => (props.full ? 45 : 25)}px !important;
  height: 100% !important;
  position: relative;

  span:not(.ant-btn-loading-icon) {
    width: 101%;
  }

  span.ant-btn-loading-icon {
    position: absolute;
    left: ${(props) => (props.full ? 20 : 10)}px;
    top: 25%;
  }
`;

export default poppinMixin;
