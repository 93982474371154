import React, { useState, useEffect, useRef } from 'react';
import PropType from 'prop-types';
import { select } from 'd3-selection';
import { transition } from 'd3-transition';
import { easeLinear } from 'd3-ease';
import { timeDay } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';
import { min, max, median } from 'd3-array';
import { axisBottom, axisRight } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { line } from 'd3-shape';
import styled from 'styled-components';
import {
  poppinMixin, GRAY_5, GRAY_8, GRAY_9, ORANGE_1,
} from 'util/styled';
import { dateDiff, dateFormat, addHours } from 'util/date';

const Root = styled.div`
  position: relative;
`;

const Svg = styled.svg`
  .y-axis .domain {
    display: none;
  }

  .tick {
    ${poppinMixin(14, '500', GRAY_5)}
    stroke: ${GRAY_5};
  }

  .domain {
    stroke: ${GRAY_8};
    stroke-width: 2;
  }
`;

const Text = styled(({
  className, children, x, y,
}) => (
  <text className={className} x={x} y={y}>
    {children}
  </text>
))`
    ${(props) => poppinMixin(14, '600', props.color)}
    fill: ${(props) => props.color};
`;

const BackgroundRect = styled.rect`
  fill: ${GRAY_9};
`;

const HiddenRect = styled.rect`
  opacity: 0;
`;

const PastPath = styled.path`
  fill: none;
  stroke: ${GRAY_5};
  stroke-width: 2;
  stroke-dasharray: 3 2;
`;

const ForecastPath = styled.path`
  fill: none;
  stroke: ${ORANGE_1};
  stroke-width: 2;
`;

const DataCircle = styled.circle`
  stroke: ${ORANGE_1};
  r: 3;
  stroke-width: 2;
  fill: #fff;
`;

const Tooltip = styled.div`
  ${poppinMixin(12)}
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  border-radius: 3px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px ${GRAY_9};
  background-color: #ffffff;
  padding: 3px 10px;
  transform: translate(-50%, calc(-100% - 10px));
  min-width: 90px;
`;

const margin = {
  top: 20,
  right: 0,
  bottom: 20,
  left: 15,
};

const linearTransition = transition().duration(400).ease(easeLinear);

const bottomAxisSvg = (svg, x, height) => {
  svg
    .append('g')
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .call(
      axisBottom(x)
        .ticks(timeDay.every(1))
        .tickFormat((d) => {
          if ([0, 4].includes(d.getDay())) {
            return timeFormat('%a')(d).substr(0, 2);
          }
          return timeFormat('%a')(d)[0];
        })
        .tickSize(0)
        .tickPadding(8),
    );
};

const leftAxisSvg = (svg, y, minY, maxY, offset) => {
  const updateAxis = svg.selectAll('.y-axis').data([1]);

  let tickValues = [];
  if (minY !== undefined) {
    tickValues = [minY - offset, minY + (maxY - minY) / 2, maxY + offset];
  }

  const drawGraph = (g) => g.call(
    axisRight(y)
      .tickValues(tickValues, 'd')
      .tickFormat(format(',d'))
      .tickSize(0)
      .tickPadding(-15),
  );

  updateAxis
    .enter()
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${margin.left},0)`)
    .call(drawGraph);

  updateAxis.transition(linearTransition).call(drawGraph);
};

const fillEmptyDataValues = (rawData) => {
  const medianValue = median(rawData.map((d) => d.value));
  return [...Array(7)].map((_, i) => {
    const date = dateDiff(i - 4);
    const datum = rawData.filter((d) => d.date === date.getTime());
    return {
      date,
      value: datum.length > 0 && datum[0].value ? datum[0].value : medianValue,
    };
  });
};

const TrendGraph = ({ unit, data: rawData, trendName }) => {
  const [tooltip, setTooltip] = useState(null);
  const [doFadeTransition, setDoFadeTransition] = useState(true);

  const d3Ref = useRef(null);
  const width = 300;
  const height = 200;

  const data = fillEmptyDataValues(rawData);

  const today = dateDiff(0);

  const minY = min(data, (d) => +d.value);
  const maxY = max(data, (d) => +d.value);
  const xOffsetHours = 7;
  const minX = +dateDiff(-5, 24 - xOffsetHours);
  const maxX = +dateDiff(2, xOffsetHours);

  const x = scaleTime()
    .domain([minX, maxX])
    .range([margin.left, width - margin.right]);

  const offset = Math.max(Math.abs(maxY - minY) * 0.1, 1);
  const yAxisMargin = Math.max((maxY - minY) * 0.2, 0.2);
  const y = scaleLinear()
    .domain([minY - offset - yAxisMargin, maxY + offset + yAxisMargin])
    .range([height - margin.bottom, margin.top]);

  useEffect(() => {
    try {
      if (!d3Ref.current) return;

      const past = data.filter((d) => d.date < today);
      const forecast = data.filter((d) => d.date >= today);

      const lineFn = line()
        .x((d) => x(d.date))
        .y((d) => y(d.value));

      const svg = select(d3Ref.current);

      leftAxisSvg(svg, y, minY, maxY, offset);
      bottomAxisSvg(svg, x, height);

      const pastAndFirstForecast = [...past, forecast[0]];
      let transitionFunction = (className, lineData) => svg
        .selectAll(className)
        .data([1])
        .transition(linearTransition)
        .attr('d', lineFn(lineData));

      if (doFadeTransition) {
        transitionFunction = (className, lineData) => svg
          .selectAll(className)
          .data([1])
          .attr('d', lineFn(lineData))
          .attr('opacity', 0)
          .transition(linearTransition)
          .attr('opacity', 1);
      }

      transitionFunction(PastPath, pastAndFirstForecast);
      transitionFunction(ForecastPath, forecast);

      // If we don't yet have real data, show a fade rather than ease.
      setDoFadeTransition(past[0].value === undefined);
    } catch (e) {
      // D3 transitions can throw exceptions if a user clicks around too fast -
      // just let the next render fix it.
    }
  }, [unit, data, today, x, y, offset, minY, maxX, maxY, doFadeTransition]);

  return (
    <Root onMouseLeave={() => setTooltip(null)} aria-label={`${trendName} graph`}>
      <Svg ref={d3Ref} viewBox={`0 0 ${width} ${height}`}>
        <Text x={0} y={15} color={GRAY_5}>
          {unit}
        </Text>
        <Text x={210} y={15} color={ORANGE_1}>
          Forecast
        </Text>

        <BackgroundRect
          x={x(+dateDiff(0))}
          y={20}
          width={x(maxX) + x(+dateDiff(0))}
          height={height - 40}
        />

        {data.map((point) => (
          <HiddenRect
            key={point.date}
            x={x(addHours(point.date, -12))}
            y={20}
            width={x(maxX) + x(+dateDiff(0))}
            height={height - 40}
            onMouseOver={() => {
              // Scale the x/y functions by the actual size of the SVG element
              // (possibly scaled by CSS rules outside this container).
              const boundingRect = d3Ref.current.getBoundingClientRect();
              setTooltip({
                ...point,
                x: x(point.date) * (boundingRect.width / width),
                y: y(point.value) * (boundingRect.height / height),
              });
            }}
          />
        ))}

        {data.filter((p) => p.value !== undefined).length > 0 && (
          <g>
            <PastPath />
            <ForecastPath />
          </g>
        )}

        {tooltip && tooltip.value && <DataCircle cx={x(tooltip.date)} cy={y(tooltip.value)} aria-label={`${trendName} circle`} />}
      </Svg>
      {tooltip && tooltip.value ? (
        <Tooltip x={tooltip.x} y={tooltip.y}>
          {`${dateFormat(tooltip.date)}: ${tooltip.value.toFixed(2)}`}
        </Tooltip>
      ) : null}
    </Root>
  );
};

TrendGraph.defaultProps = {
  trendName: '',
};

TrendGraph.propTypes = {
  data: PropType.arrayOf(PropType.any).isRequired,
  unit: PropType.string.isRequired,
  trendName: PropType.string,
};

export default TrendGraph;
