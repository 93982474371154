import {
  SET_DOWNLOAD_BY,
  ADD_DOWNLOAD_DATASET,
  REMOVE_DOWNLOAD_DATASET,
  ADD_REGION,
  REMOVE_REGION,
  CLEAR_SELECTIONS,
} from 'actions';
import { REGIONS } from 'config/downloads';

const DEFAULT_STATE = {
  downloadBy: REGIONS[0].name,
  selectedLayerTypes: [],
  selectedRegions: [],
};

export default (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case SET_DOWNLOAD_BY:
      if (state.downloadBy === action.payload) return state;

      return {
        ...state,
        downloadBy: action.payload,
        selectedRegions: [],
        selectedLayerTypes: [],
      };
    case ADD_DOWNLOAD_DATASET: {
      const index = state.selectedLayerTypes
        .findIndex((layerType) => layerType === action.payload);
      if (index !== -1) return state;

      return {
        ...state,
        selectedLayerTypes: [
          ...state.selectedLayerTypes,
          action.payload,
        ],
      };
    }
    case REMOVE_DOWNLOAD_DATASET:
      return {
        ...state,
        selectedLayerTypes: state.selectedLayerTypes.filter((lt) => lt !== action.payload),
      };
    case ADD_REGION: {
      const index = state.selectedRegions
        .findIndex((region) => region === action.payload);
      if (index !== -1) return state;

      return {
        ...state,
        selectedRegions: [
          ...state.selectedRegions,
          action.payload,
        ],
      };
    }
    case REMOVE_REGION: {
      return {
        ...state,
        selectedRegions: state.selectedRegions.filter((r) => r !== action.payload),
      };
    }
    case CLEAR_SELECTIONS:
      return {
        ...state,
        selectedRegions: [],
        selectedLayerTypes: [],
      };
    default:
      return state;
  }
};
