import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  TAB_LAYERS,
  TAB_TRENDS,
  TAB_DOWNLOAD,
  TAB_EXPLORE,
} from 'reducers/nav';
import { sendAnalyticsEvent } from 'util/analytics';
import styles from './index.module.css';

const StyledRoot = styled.div`
  text-align: ${isMobile ? 'center' : 'left'};
`;

const TabMenuItem = (props) => {
  const {
    ariaLabel, className, onSelected, children,
  } = props;
  return (
    <div
      aria-label={ariaLabel}
      className={`${styles.tab} ${className}`}
      role="button"
      tabIndex={0}
      onClick={onSelected}
      onKeyDown={onSelected}
    >
      <div className={styles.icon} />
      <div className={styles.name}>
        {children}
      </div>
    </div>
  );
};

TabMenuItem.defaultProps = {
  className: '',
  children: undefined,
  ariaLabel: '',
};

TabMenuItem.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onSelected: PropTypes.func.isRequired,
};

const TabMenu = ({
  onLayersSelected, onTrendsSelected, onDownloadSelected, onExploreSelected,
}) => {
  const tab = useSelector((state) => state.nav.tab);

  const layersSelectedClass = tab === TAB_LAYERS ? styles.selected : '';
  const trendsSelectedClass = tab === TAB_TRENDS ? styles.selected : '';
  const downloadSelectedClass = tab === TAB_DOWNLOAD ? styles.selected : '';
  const exploreSelectedClass = tab === TAB_EXPLORE ? styles.selected : '';

  const onSelect = (cb, newTab) => () => {
    sendAnalyticsEvent('tab_selected', { tab_name: newTab });
    cb();
  };

  return (
    <StyledRoot>
      <TabMenuItem
        ariaLabel="Layers tab"
        className={`${styles.layers} ${layersSelectedClass}`}
        onSelected={onSelect(onLayersSelected, TAB_LAYERS)}
      >
        Layers
      </TabMenuItem>
      <TabMenuItem
        ariaLabel="Trends tab"
        className={`${styles.trends} ${trendsSelectedClass}`}
        onSelected={onSelect(onTrendsSelected, TAB_TRENDS)}
      >
        Trends
      </TabMenuItem>
      {!isMobile && (
      <TabMenuItem
        ariaLabel="Download tab"
        className={`${styles.download} ${downloadSelectedClass}`}
        onSelected={onSelect(onDownloadSelected, TAB_DOWNLOAD)}
      >
        Download
      </TabMenuItem>
      )}
      <TabMenuItem
        ariaLabel="Explore tab"
        className={`${styles.explore} ${exploreSelectedClass}`}
        onSelected={onSelect(onExploreSelected, TAB_EXPLORE)}
      >
        Explore
      </TabMenuItem>
    </StyledRoot>
  );
};

TabMenu.propTypes = {
  onLayersSelected: PropTypes.func.isRequired,
  onTrendsSelected: PropTypes.func.isRequired,
  onDownloadSelected: PropTypes.func.isRequired,
  onExploreSelected: PropTypes.func.isRequired,
};

export default TabMenu;
