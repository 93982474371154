import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropType from 'prop-types';
import styled from 'styled-components';
import { closeWelcomePopup } from 'actions/welcome';
import Popup from 'containers/popup';
import {
  Button, ExternalLink, ExternalLinkUnderlined, Link, Text, GRAY_1, GREEN_1,
} from 'util/styled';
import TOURS from 'config/tour';
import { sendAnalyticsEvent } from 'util/analytics';

const Root = styled.div`
  display: flex;
  flex-direction: column;

  .body {
    display: flex;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    height: 310px;

    .image {
      flex: 1;
      padding-right: ${(props) => (props.reverse ? '0px' : '20px')};

      img {
        display: inherit;
        margin-left: ${(props) => (props.reverse ? 'auto' : 'unset')};
        max-width: 330px;
        max-height: 260px;
      }
    }

    .text {
      flex: 1;
      padding-right: ${(props) => (props.reverse ? '20px' : '0px')};
    }
  }

  .bottom {
    margin: 0 auto;
    
    // Edge
    @supports (-ms-ime-align:auto) {
      padding-bottom: 15px;
    }

    // IE10+    
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {    
      text-align: center;
      padding-bottom: 15px;
    }

    ${Button} {
      width: min-content;
      
      // IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 40px; // for IE11 render--the outline takes up space on focus
      }
    }

    ${Link} {
      text-align: center;
      display: block;
    }

    ${Text} {
      margin-top: 10px;
    }
  }

  ${Text} {
    margin-bottom: 10px;
  }
`;

const TourPopup = ({ index, onClose }) => {
  const [tourIndex, setTourindex] = useState(index);
  const dispatch = useDispatch();
  const tour = TOURS[tourIndex];

  // on mount only, let analytics know we've seen this index:
  useEffect(() => {
    sendAnalyticsEvent('take_a_tour_page_shown', { page_number: 1 });
  }, []);

  const markAsTouredAndClose = () => {
    dispatch(closeWelcomePopup());
    onClose();
  };

  const incrementTour = () => {
    const newIndex = tourIndex + 1;
    sendAnalyticsEvent('take_a_tour_page_shown', { page_number: newIndex });
    setTourindex(newIndex);
  };

  return (
    <Popup
      onClosePopup={markAsTouredAndClose}
      width={720}
      height={480}
      hPad={20}
      showLogo
    >
      <Root reverse={tour.reverse}>
        <div className="body">
          <div className="image">
            {tour.imageLink
              && (
              <ExternalLink href={tour.imageLink}>
                <img src={tour.imagePath} alt={tour.title} />
              </ExternalLink>
              )}
            {!tour.imageLink
              && <img src={tour.imagePath} alt={tour.title} />}
          </div>
          <div className="text">
            {tour.title && (
              <Text size={12} weight="600" color={GRAY_1}>
                {tour.title}
              </Text>
            )}
            {tour.body.map((body) => (
              <Text key={`${index}`} size={12} color={GRAY_1}>
                {body}
              </Text>
            ))}
          </div>
        </div>

        <div className="bottom">
          {tourIndex < TOURS.length - 1 ? (
            <Button onClick={incrementTour}>Next</Button>
          ) : (
            <Link
              size={18}
              weight="800"
              color={GREEN_1}
              onClick={markAsTouredAndClose}
            >
              EXPLORE THE FOREST
            </Link>
          )}

          {tourIndex === TOURS.length - 1 ? (
            <Text size={10} align="center">
              By closing this window and using this site you acknowledge you
              have read, understand, and accept the
              {' '}
              <ExternalLinkUnderlined size={10} href="/legal.html">Terms of Use</ExternalLinkUnderlined>
              .
            </Text>
          ) : (
            <div />
          )}
        </div>
      </Root>
    </Popup>
  );
};
TourPopup.propTypes = {
  index: PropType.number,
  onClose: PropType.func,
};
TourPopup.defaultProps = {
  index: 0,
  onClose: () => {},
};

export default TourPopup;
