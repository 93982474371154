import {
  makeWMSUrl,
  LAYERS,
  LAYER_LADDER_FUEL_DENSITY,
  LAYER_CANOPY_COVER,
  LAYER_CANOPY_BULK_DENSITY,
  YEAR_2016,
  YEAR_2018,
  YEAR_2019,
  YEAR_2020,
} from './layers';

export const CAMP_FIRE = {
  name: 'The Camp Fire’s Ecological Footprint',
  description: 'Mapping foliar change before and after catastrophe.',
  layerType: LAYER_CANOPY_BULK_DENSITY,
  url: makeWMSUrl(LAYERS[LAYER_CANOPY_BULK_DENSITY]),
  zoom: 12,
  center: [39.75, -121.55],
  pre: {
    year: YEAR_2018,
    layer: LAYERS[LAYER_CANOPY_BULK_DENSITY],
  },
  post: {
    year: YEAR_2019,
    layer: LAYERS[LAYER_CANOPY_BULK_DENSITY],
  },
};

const NORTH_COAST = {
  name: 'Timber Harvest in the North Coast',
  description: 'A mosaic checkerbox change.',
  layerType: LAYER_CANOPY_COVER,
  url: makeWMSUrl(LAYERS[LAYER_CANOPY_COVER]),
  zoom: 13,
  center: [41.28, -123.94],
  pre: {
    year: YEAR_2016,
    layer: LAYERS[LAYER_CANOPY_COVER],
  },
  post: {
    year: YEAR_2020,
    layer: LAYERS[LAYER_CANOPY_COVER],
  },
};

const CHAPARRAL = {
  name: 'Chaparral Regrowth post-Thomas Fire',
  description: 'Fast growing, fast moving regeneration.',
  layerType: LAYER_LADDER_FUEL_DENSITY,
  url: makeWMSUrl(LAYERS[LAYER_LADDER_FUEL_DENSITY]),
  zoom: 11,
  center: [34.52, -119.47],
  pre: {
    year: YEAR_2018,
    layer: LAYERS[LAYER_LADDER_FUEL_DENSITY],
  },
  post: {
    year: YEAR_2020,
    layer: LAYERS[LAYER_LADDER_FUEL_DENSITY],
  },
};

export const EXPLORATIONS = [CAMP_FIRE, NORTH_COAST, CHAPARRAL];

export default EXPLORATIONS;
