import {
  CLEAR_POI,
  SET_POI,
  UPDATE_LAYER_POI,
  SET_LAYER_VISIBILITY,
  SET_LAYER_OPACITY,
  SET_LAYER_YEAR,
  SET_LAYER_POPUP,
  SET_DAILY_POPUP,
} from 'actions';
import { requestDataForPoi } from 'services';
import { LAYERS } from 'config/layers';
import { setTrendsPointOfInterest } from './trends';

const fetchLayerData = (lat, lng, layer, dispatch) => {
  const catalogAndAsset = LAYERS[layer.layerType][layer.year].layers;
  requestDataForPoi(layer, lat, lng)
    .then((response) => {
      let value = 'n/a';
      if (typeof response.data.val === 'number') {
        value = response.data.val.toFixed(2);
      }
      dispatch({
        type: UPDATE_LAYER_POI,
        payload: {
          lat,
          lng,
          id: catalogAndAsset,
          value,
        },
      });
    })
    .catch(() => {
      // on failure, don't update the POI.
    });
};

const fetchMissingPoiData = (dispatch, getState) => {
  if (getState().layers.poi === null) return;

  const visibleLayers = getState().layers.layers.filter((l) => l.visibility);
  const hasPOI = visibleLayers.filter((l) => LAYERS[l.layerType].hasPOI);

  const { lat, lng } = getState().layers.poi;
  hasPOI.forEach((layer) => {
    // don't fetch data if we already have it:
    const id = LAYERS[layer.layerType][layer.year].layers;
    if (id in getState().layers.poi) return;

    fetchLayerData(lat, lng, layer, dispatch);
  });
};

export const setLayerVisibility = (layerType, visibility) => async (
  dispatch,
  getState,
) => {
  await dispatch({
    type: SET_LAYER_VISIBILITY,
    payload: {
      layerType,
      visibility,
    },
  });

  if (visibility) {
    fetchMissingPoiData(dispatch, getState);
  }
};

export const setLayerOpacity = (layerType, opacity) => ({
  type: SET_LAYER_OPACITY,
  payload: {
    layerType,
    opacity,
  },
});

export const setLayerYear = (layerType, year) => async (dispatch, getState) => {
  await dispatch({
    type: SET_LAYER_YEAR,
    payload: {
      layerType,
      year,
    },
  });

  fetchMissingPoiData(dispatch, getState);
};

export const setLayerPopup = (layerType) => ({
  type: SET_LAYER_POPUP,
  payload: layerType,
});

export const setDailyPopup = (layerType) => ({
  type: SET_DAILY_POPUP,
  payload: layerType,
});

export const setPointOfInterest = (lat, lng) => async (dispatch, getState) => {
  await dispatch({
    type: SET_POI,
    payload: { lat, lng },
  });

  fetchMissingPoiData(dispatch, getState);

  await setTrendsPointOfInterest(lat, lng)(dispatch);
};

export const clearPointOfInterest = () => ({ type: CLEAR_POI });
