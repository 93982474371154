import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { Button, poppinMixin, GRAY_10 } from 'util/styled';
import { openLoginPopup } from 'actions/user';
import {
  clearSelections,
  setDownloadBy,
  addDownloadDataset,
  removeDownloadDataset,
} from 'actions/downloads';
import { useSelector, useDispatch } from 'react-redux';
import { REGIONS } from 'config/downloads';
import { requestRegionAsset } from 'services';
import { sendAnalyticsEvent } from 'util/analytics';
import Nav, { Content, StyledSlugMessage } from '../nav';
import SelectedData from './selecteddata';
import Datasets from './datasets';
import StyledCombobox from './combobox';

const PasswordButton = ({
  className, children, disabled, onClick, loading,
}) => {
  const dispatch = useDispatch();
  const [attemptingLogin, setAttemptingLogin] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const hasProfile = profile !== null;

  const checkLoginStatus = () => {
    if (!hasProfile) {
      setAttemptingLogin(true);
      dispatch(openLoginPopup());
      return;
    }

    onClick();
  };

  // on successful login, go ahead and process the downloads:
  let isLoading = loading;
  if (attemptingLogin && hasProfile) {
    isLoading = true;
    setAttemptingLogin(false);
    onClick();
  }

  return (
    <Button
      className={className}
      onClick={checkLoginStatus}
      disabled={disabled}
      loading={isLoading}
    >
      {children}
    </Button>
  );
};
PasswordButton.propTypes = {
  disabled: PropType.bool.isRequired,
  onClick: PropType.func.isRequired,
  loading: PropType.bool.isRequired,
  className: PropType.string.isRequired,
  children: PropType.node.isRequired,
};

const StyledPasswordButton = styled(PasswordButton)`
  font-size: ${(props) => (props.disabled ? 9 : 11)}px !important;
  max-width: 137px;
  margin: 20px auto;
`;

const DownloadButton = ({ downloadBy, selectedLayerTypes, selectedRegions }) => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);

  const [urls, setUrls] = useState([]);
  const showDownload = selectedRegions.length > 0 && selectedLayerTypes.length > 0;

  const downloadFn = async () => {
    sendAnalyticsEvent('dataset_downloaded', {
      dataset_names: selectedRegions.join(','),
    });

    const allLinks = selectedRegions
      .map(
        (region) => selectedLayerTypes.map(
          (layerType) => requestRegionAsset(layerType, region, downloadBy, profile.token),
        ),
      )
      .flat();

    const results = await Promise.all(allLinks.map((p) => p.catch(() => null)));
    const availableResponses = results.filter((result) => result !== null);
    const urlsToFetch = availableResponses.map((response) => response.data.link);
    setUrls(urlsToFetch);
  };

  // the browser needs a few seconds between each file in order to acknowledge
  // the download attempt:
  const delay = () => new Promise((resolve) => setTimeout(resolve, 2000));

  useEffect(() => {
    const downloadUrls = async () => {
      // eslint-disable-next-line
      for (const url of urls) {
        document.querySelector(`a[href="${url}"]`).click();
        // eslint-disable-next-line
        await delay();
      }
      if (urls.length > 0) {
        setUrls([]);
        dispatch(clearSelections());
      }
    };
    downloadUrls();
  }, [dispatch, urls]);

  return (
    <StyledPasswordButton
      disabled={!showDownload}
      onClick={downloadFn}
      loading={urls.length > 0}
    >
      {showDownload ? (
        <div aria-label="Download dataset">
          DOWNLOAD
        </div>
      ) : (
        <div>
          Select a dataset
          {' '}
          <br />
          to download
        </div>
      )}
      {urls.map((url) => (
        <HiddenDownloadLink
          key={url}
          href={url}
          download={url.substring(url.lastIndexOf('/') + 1)}
          type="application/octet-stream"
        />
      ))}
    </StyledPasswordButton>
  );
};
DownloadButton.propTypes = {
  downloadBy: PropType.string.isRequired,
  selectedLayerTypes: PropType.arrayOf(PropType.shape(PropType.string)).isRequired,
  selectedRegions: PropType.arrayOf(PropType.shape(PropType.string)).isRequired,
};

const DownloadBy = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  ${poppinMixin(14, '600')}

  div {
    width: 50%;
  }
  select {
    width: 50%;
  }
`;

const StyledNav = styled(Nav)`
  background: white;

  ${Content} {
    padding-top: 0px !important;
    border-top: 12px solid ${GRAY_10};
  }
`;

const HiddenDownloadLink = styled.a`
  display: none;
`;

const Download = () => {
  const downloadBy = useSelector((state) => state.downloads.downloadBy);
  const selectedLayerTypes = useSelector(
    (state) => state.downloads.selectedLayerTypes,
  );
  const selectedRegions = useSelector(
    (state) => state.downloads.selectedRegions,
  );
  const currentIndex = REGIONS.findIndex(
    (region) => region.name === downloadBy,
  );
  const dispatch = useDispatch();

  return (
    <StyledNav>
      <DownloadBy>
        <div>Download By:</div>
        <StyledCombobox
          options={REGIONS.map((region) => region.name)}
          defaultValue={downloadBy}
          onChange={(option) => dispatch(setDownloadBy(option))}
        />
      </DownloadBy>
      <SelectedData
        mapType={REGIONS[currentIndex].name}
        mapNumber={selectedRegions.length}
        datasetsNumber={selectedLayerTypes.length}
      />
      <Datasets
        selectedLayerTypes={selectedLayerTypes}
        onChange={(name) => {
          if (selectedLayerTypes.includes(name)) {
            dispatch(removeDownloadDataset(name));
            return;
          }
          dispatch(addDownloadDataset(name));
        }}
      />
      <StyledSlugMessage message="Our data are free—with commercial restrictions. Please read The Terms.">
        <DownloadButton
          downloadBy={REGIONS[currentIndex].geographyType}
          selectedLayerTypes={selectedLayerTypes}
          selectedRegions={selectedRegions}
        />
      </StyledSlugMessage>
    </StyledNav>
  );
};

export default Download;
