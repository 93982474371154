import React from 'react';
import { useSelector } from 'react-redux';
import {
  DAILY_LAYERS,
  ANNUAL_LAYERS,
} from 'config/layers';
import styled from 'styled-components';
import { poppinMixin, GRAY_2 } from 'util/styled';
import Layer from './layer';
import { YearPopup } from './year-popup';
import { DailyPopup } from './daily-popup';
import Nav, { StyledSlugMessage } from '../nav';

const Header = styled.div`
  padding: 10px 16px 10px 16px;
  ${poppinMixin(14, '600', GRAY_2)}
`;

const Layers = () => {
  const showYearPopup = useSelector((state) => state.layers.showYearPopup);
  const showDailyPopup = useSelector((state) => state.layers.showDailyPopup);

  return (
    <Nav>
      <Header>DAILY (today)</Header>
      {
        DAILY_LAYERS.map((l) => (
          <Layer
            key={l.layerType}
            ariaLabel={`${l.name} layer`}
            name={l.name}
            layerType={l.layerType}
            scale={l.scale}
            showHelp={false}
            showDailyHelp
            isAnnual={false}
          />
        ))
      }
      {!!showDailyPopup && <DailyPopup layerType={showDailyPopup} />}
      <Header>ANNUAL</Header>
      {
        ANNUAL_LAYERS.map((l) => (
          <Layer
            key={l.layerType}
            ariaLabel={`${l.name} layer`}
            name={l.name}
            layerType={l.layerType}
            scale={l.scale}
            showHelp
            showDailyHelp={false}
            isAnnual
          />
        ))
      }
      {!!showYearPopup && <YearPopup layerType={showYearPopup} />}
      <StyledSlugMessage message="Click on the map to show layer values at that location." />
    </Nav>
  );
};

export default Layers;
