import React from 'react';
import { LAYERS } from 'config/layers';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from 'containers/popup';
import { setDailyPopup } from 'actions/layers';
import styled from 'styled-components';
import {
  SpansAndContent,
  ImageAndDetails,
  Image,
  NameAndDescription,
  Name,
  Description,
  ModelPerformance,
  Metrics,
} from '../year-popup/index';

const NoSteps = styled.div`
  width: 60% !important;
  margin: 0 auto 36px auto !important;
`;

export const DailyPopup = ({ layerType }) => {
  const layers = useSelector((state) => state.layers.layers);
  const dispatch = useDispatch();

  const layer = layers.find((l) => l.layerType === layerType);
  const layerConfig = LAYERS[layer.layerType];

  return (
    <Popup
      height={350}
      width={475}
      hPad={35}
      marginLeft="314px"
      onClosePopup={() => dispatch(setDailyPopup(null))}
    >
      <SpansAndContent>
        <NoSteps />
        <ImageAndDetails>
          {layerConfig.imagePath !== null && (
            <Image src={layerConfig.imagePath} alt={layerConfig.name} />
          )}

          <NameAndDescription>
            <Name>{layerConfig.name}</Name>
            <Description>{layerConfig.description}</Description>
            <ModelPerformance>Model performance</ModelPerformance>
            <Metrics>
              <dt>r2</dt>
              <dd>{layerConfig.r2 || 'N/A'}</dd>
              <dt>MAE</dt>
              <dd>{layerConfig.mae || 'N/A'}</dd>
              <dt>RMSE</dt>
              <dd>{layerConfig.rmse || 'N/A'}</dd>
            </Metrics>
          </NameAndDescription>
        </ImageAndDetails>
      </SpansAndContent>
    </Popup>
  );
};
DailyPopup.propTypes = {
  layerType: PropTypes.string.isRequired,
};

export default DailyPopup;
