import {
  CLOSE_WELCOME_POPUP,
  SEEN_MOBILE_POPUP,
} from 'actions';

export const closeWelcomePopup = () => ({
  type: CLOSE_WELCOME_POPUP,
});

export const seenMobilePopup = () => ({
  type: SEEN_MOBILE_POPUP,
});
