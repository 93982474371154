import React from 'react';
import { LAYERS, YEARS } from 'config/layers';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from 'containers/popup';
import { setLayerYear, setLayerPopup } from 'actions/layers';
import styled from 'styled-components';
import { poppinMixin, GRAY_5 } from 'util/styled';
import { StyledSteps, StyledStep } from './steps';

export const SpansAndContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 70%;
  margin-top: 10px;
`;

export const ImageAndDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  margin-bottom: 20px;
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-bottom: 20px;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  
  // Edge
  @supports (-ms-ime-align:auto) {
    height: 210px;
  }
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 210px;
  }
  
  width: auto;
  margin-top: -10px;
  margin-right: 12px;
`;

export const NameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  ${poppinMixin(15, '500')}
  line-height: 21px;
`;

export const Name = styled.div`
  ${poppinMixin(18, '600')}
  margin-bottom: 15px;
`;

export const Description = styled.div`
  flex-grow: 1;
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 314px;
  }
`;

export const ModelPerformance = styled.div`
  text-transform: uppercase;
  ${poppinMixin(11, 'bold', GRAY_5)}
`;

export const Metrics = styled.dl`
  display: flex;
  flex-direction: row;
  ${poppinMixin(11, 'normal', GRAY_5)}
  margin-bottom: 0px;

  dt {
    margin-right: 0.5rem;
  }
  dt::after {
    content: ":";
  }

  dd {
    margin-right: 1.5rem;
  }
`;

export const YearPopup = ({ layerType }) => {
  const layers = useSelector((state) => state.layers.layers);
  const dispatch = useDispatch();

  const layer = layers.find((l) => l.layerType === layerType);
  const layerConfig = LAYERS[layer.layerType];
  const yearIndex = YEARS.indexOf(layer.year);

  return (
    <Popup
      height={350}
      width={475}
      hPad={35}
      marginLeft="314px"
      onClosePopup={() => dispatch(setLayerPopup(null))}
    >
      <SpansAndContent>
        <StyledSteps
          progressDot="iconDot"
          current={yearIndex}
          onChange={(index) => dispatch(setLayerYear(layer.layerType, YEARS[index]))}
        >
          {YEARS.map((year) => (
            <StyledStep key={year} title={year.toString()} aria-label={`${layer.layerType} year ${year} step`} />
          ))}
        </StyledSteps>
        <ImageAndDetails>
          {layerConfig.imagePath !== null && (
            <Image src={layerConfig.imagePath} alt={layerConfig.name} />
          )}

          <NameAndDescription>
            <Name>{layerConfig.name}</Name>
            <Description>{layerConfig.description}</Description>
            <ModelPerformance>Model performance</ModelPerformance>
            <Metrics>
              <dt>r2</dt>
              <dd>{layerConfig.r2 || 'N/A'}</dd>
              <dt>MAE</dt>
              <dd>{layerConfig.mae || 'N/A'}</dd>
              <dt>RMSE</dt>
              <dd>{layerConfig.rmse || 'N/A'}</dd>
            </Metrics>
          </NameAndDescription>
        </ImageAndDetails>
      </SpansAndContent>
    </Popup>
  );
};
YearPopup.propTypes = {
  layerType: PropTypes.string.isRequired,
};

export default YearPopup;
