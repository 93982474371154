import {
  CREATE_USER,
  LOGIN_USER,
  OPEN_NAV,
  CLOSE_NAV,
  SET_LAYER_VISIBILITY,
  SET_LAYER_YEAR,
  SET_DOWNLOAD_BY,
  ADD_DOWNLOAD_DATASET,
  ADD_REGION,
  SET_POI,
  SET_EXPLORE_LAYER,
} from 'actions';

export const sendAnalyticsEvent = (action, value = {}) => (
  window.gtag('event', action, value)
);

export const analyticsMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case LOGIN_USER: {
      sendAnalyticsEvent('account_signed_in');
      break;
    }
    case CREATE_USER: {
      sendAnalyticsEvent('account_created');
      break;
    }
    case OPEN_NAV:
      sendAnalyticsEvent('navigation_expanded');
      break;
    case CLOSE_NAV:
      sendAnalyticsEvent('navigation_collapsed');
      break;
    case SET_LAYER_VISIBILITY:
      sendAnalyticsEvent('layer_visibility_changed', {
        layer_name: action.payload.layerType,
        visible: action.payload.visibility,
      });
      break;
    case SET_LAYER_YEAR:
      sendAnalyticsEvent('layer_year_changed', {
        layer_name: action.payload.layerType,
        year: action.payload.year,
      });
      break;
    case SET_DOWNLOAD_BY:
      sendAnalyticsEvent('download_geography_type_selected', {
        geography_type: action.payload,
      });
      break;
    case ADD_DOWNLOAD_DATASET:
      sendAnalyticsEvent('download_dataset_selected', {
        dataset_name: action.payload,
      });
      break;
    case SET_POI:
      sendAnalyticsEvent('location_selected', {
        lat: action.payload.lat,
        lon: action.payload.lng,
      });
      break;
    case SET_EXPLORE_LAYER:
      sendAnalyticsEvent('explore_dataset_selected', {
        dataset_name: action.payload.layerType,
        state: action.payload.isPost ? 'post' : 'pre',
      });
      break;
    case ADD_REGION: {
      const state = store.getState();
      sendAnalyticsEvent('download_geography_selected', {
        geography_type: state.downloads.downloadBy,
        geography_name: action.payload,
      });
      break;
    }
    default:
  }

  return result;
};
