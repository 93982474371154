import {
  SET_EXPLORE_LAYER,
} from 'actions';
import { CAMP_FIRE } from 'config/explore';

const DEFAULT_STATE = {
  layerType: CAMP_FIRE.layerType,
  isPost: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_EXPLORE_LAYER:
      return {
        layerType: action.payload.layerType,
        isPost: action.payload.isPost,
      };
    default:
      return state;
  }
};
