import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'actions/user';
import Popup from 'containers/popup';
import {
  ExternalLink, Link, Text,
} from 'util/styled';
import {
  ROUTE_TOUR,
  ROUTE_ABOUT,
  ROUTE_CONTACT,
  ROUTE_LEGAL,
} from 'config/routes';
import PropType from 'prop-types';
import styled from 'styled-components';
import SignInForm from './signin';

const Root = styled.div`
  ${Text} {
    margin-bottom: 15px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const HeaderLink = styled(Link)`
  text-transform: uppercase;
  margin-bottom: 45px;
`;

export const AboutLink = styled(ExternalLink)`
  text-transform: uppercase;
  margin-bottom: 45px;
`;

const MenuLoginPopup = ({ onClose }) => {
  const authInProgress = useSelector((state) => state.user.authInProgress);
  const authError = useSelector((state) => state.user.authError);
  const dispatch = useDispatch();

  const attemptSignin = (values) => {
    dispatch(loginUser(values.email, values.password));
  };

  return (
    <Popup
      onClosePopup={onClose}
      width={380}
      height={authError ? 525 : 475}
      hPad={50}
      marginRight="60px"
      marginTop={() => '30px'}
      size="half"
      showLogo
      showFooter
    >
      <Root>
        <Menu>
          <HeaderLink to={ROUTE_TOUR}>Take a tour</HeaderLink>
          <AboutLink href={ROUTE_ABOUT}>About</AboutLink>
          <AboutLink href={ROUTE_CONTACT}>Contact</AboutLink>
          <AboutLink href={ROUTE_LEGAL}>Legal</AboutLink>
        </Menu>
        <SignInForm
          authError={authError}
          authInProgress={authInProgress}
          onFinish={attemptSignin}
          onSignup={onClose}
          size="half"
        />
      </Root>
    </Popup>
  );
};
MenuLoginPopup.propTypes = {
  onClose: PropType.func.isRequired,
};

export default MenuLoginPopup;
