import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import {
  poppinMixin, GRAY_2, GRAY_10, ORANGE_1,
} from 'util/styled';

const Row = ({
  className, number, title, subtitle,
}) => (
  <div className={className}>
    <div className="number">{number}</div>
    <div className="title-section">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  </div>
);
Row.propTypes = {
  className: PropType.string.isRequired,
  number: PropType.number.isRequired,
  title: PropType.string.isRequired,
  subtitle: PropType.string,
};
Row.defaultProps = {
  subtitle: '',
};

const StyledRow = styled(Row)`

  margin: 15px 20px;
  display: flex;
  flex-direction: row;

  .number {
    ${poppinMixin(14, '600', ORANGE_1)}
    margin-right: 15px;
  }

  .title {
    ${poppinMixin(14, '600', GRAY_2)}
  }

  .subtitle {
    ${poppinMixin(11, '500', GRAY_2)}
  }
`;

const Root = ({
  className, mapType, mapNumber, datasetsNumber,
}) => (
  <div className={className}>
    <StyledRow
      number={mapNumber}
      title={`${mapType} selected`}
      subtitle={`*Click to select ${mapType.toLowerCase()} on the map`}
    />
    <StyledRow number={datasetsNumber} title="Datasets selected" />
  </div>
);
Root.propTypes = {
  className: PropType.string.isRequired,
  mapNumber: PropType.number.isRequired,
  datasetsNumber: PropType.number.isRequired,
  mapType: PropType.string.isRequired,
};

const SelectedData = styled(Root)`
  background: ${GRAY_10} !important;
  padding: 5px;
`;

export default SelectedData;
