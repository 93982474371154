import {
  SET_EXPLORE_LAYER,
} from 'actions';

export default function setExploreLayer(layerType, isPost) {
  return {
    type: SET_EXPLORE_LAYER,
    payload: {
      layerType,
      isPost,
    },
  };
}
