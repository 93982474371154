import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, closeLoginPopup } from 'actions/user';
import Popup from 'containers/popup';
import { useHistory } from 'react-router-dom';
import { ROUTE_LAYERS } from 'config/routes';
import { Text, GRAY_1 } from 'util/styled';

import styled from 'styled-components';
import SignInForm from './signin';

const Root = styled.div`
  ${Text} {
    margin-bottom: 15px;
  }
`;

const LoginPopup = () => {
  const history = useHistory();
  const authInProgress = useSelector((state) => state.user.authInProgress);
  const authError = useSelector((state) => state.user.authError);
  const dispatch = useDispatch();

  const attemptSignin = (values) => {
    dispatch(loginUser(values.email, values.password));
  };

  const closePopup = () => {
    history.push(ROUTE_LAYERS);
    dispatch(closeLoginPopup());
  };

  return (
    <Popup onClosePopup={closePopup} width={575} showLogo>
      <Root aria-label="Download dataset login popup">
        <Text size={14} color={GRAY_1} weight="500">
          Please sign in to download
        </Text>
        <SignInForm
          authError={authError}
          authInProgress={authInProgress}
          onFinish={attemptSignin}
        />
      </Root>
    </Popup>
  );
};

export default LoginPopup;
