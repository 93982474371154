import ApiClient from 'util/api-client';
import { DEFAULT_YEAR, LAYERS, makeAssetId } from 'config/layers';
import { MAP_BOUNDS, MAPBOX_ACCESS_TOKEN } from 'config/mapbox';
import { min, max } from 'd3-array';

export const requestRegionAsset = (
  layerType,
  geography,
  geographyType,
  token,
) => {
  const layerConfig = LAYERS[layerType];

  return ApiClient.post(
    '/data/fetch',
    {
      catalog: layerConfig.catalog,
      asset_id: makeAssetId(
        layerConfig,
        DEFAULT_YEAR,
        `${geography}${geographyType}`,
      ),
      type: 'signed_url',
    },
    token,
  );
};

export const requestDataForPoi = (layer, lat, lng) => {
  const catalogAndAsset = LAYERS[layer.layerType][layer.year].layers;
  return ApiClient.post('/data/pixel_pick', {
    catalog: LAYERS[layer.layerType].catalog,
    asset_id: catalogAndAsset.match('cfo:') ? catalogAndAsset.split(':')[1] : null,
    lat,
    lng,
  });
};

/* eslint-disable */
export const requestCreateUser = (
  first_name,
  last_name,
  organization,
  email,
  password
) =>
  ApiClient.post("/users/create", {
    first_name,
    last_name,
    organization,
    email,
    password,
  });
/* eslint-enable */

export const requestLoginUser = (email, password) => ApiClient.post('/users/auth', {
  email,
  password,
});

export const requestPasswordReset = (email) => ApiClient.post('/users/auth/reset', {
  email,
});

// eslint-disable-next-line
export const requestUpdatePassword = (email, reset_token, password) => (
  ApiClient.put('/users/auth/update-password', {
    email,
    reset_token,
    password,
  })
);

export const requestRefreshJWT = (token) => ApiClient.post('/users/auth/refresh', {}, token);

export const requestDataForTrend = (trend, date, lat, lng) => ApiClient.post('/data/pixel_pick', {
  catalog: trend.catalog,
  asset_id: trend.asset_id(date),
  lat,
  lng,
});

export const requestPOISearch = (search) => {
  const minLat = min([MAP_BOUNDS[0][0], MAP_BOUNDS[1][0]]);
  const maxLat = max([MAP_BOUNDS[0][0], MAP_BOUNDS[1][0]]);
  const minLon = min([MAP_BOUNDS[0][1], MAP_BOUNDS[1][1]]);
  const maxLon = max([MAP_BOUNDS[0][1], MAP_BOUNDS[1][1]]);

  const params = {
    autocomplete: true,
    country: 'us',
    types: 'place',
    access_token: MAPBOX_ACCESS_TOKEN,
    bbox: `${minLon},${minLat},${maxLon},${maxLat}`,
    limit: 5,
  };
  const keyVals = Object.keys(params).map((k) => `${k}=${params[k]}`);
  const convertedParams = keyVals.join('&');

  return ApiClient.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      search,
    )}.json?${convertedParams}`,
  );
};

export const requestTinyURL = (state = {}) => {
  const url = new URL(document.URL);
  url.pathname = `/share/${window.btoa(
    encodeURIComponent(JSON.stringify(state)),
  )}`;

  return ApiClient.get(
    `https://tinyurl.com/api-create.php?url=${encodeURIComponent(url)}`,
  );
};
