import {
  LOGIN_USER,
  CREATE_USER,
  LOGOUT_USER,
  REFRESH_JWT,
  AUTH_FAILED,
  AUTH_IN_PROGRESS,
  CLOSE_REGISTERED_POPUP,
  OPEN_LOGIN_POPUP,
  CLOSE_LOGIN_POPUP,
} from 'actions';
import {
  requestUpdatePassword, requestCreateUser, requestLoginUser, requestRefreshJWT,
} from 'services';

export const createUser = (first, last, organization, email, password) => async (dispatch) => {
  await dispatch({ type: AUTH_IN_PROGRESS });
  requestCreateUser(first, last, organization, email, password)
    .then((response) => {
      dispatch({
        type: CREATE_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_FAILED,
        payload: err.message,
      });
    });
};

export const loginUser = (email, password) => async (dispatch) => {
  await dispatch({ type: AUTH_IN_PROGRESS });
  requestLoginUser(email, password)
    .then((response) => {
      dispatch({
        type: LOGIN_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_FAILED,
        payload: err.message,
      });
    });
};

// eslint-disable-next-line
export const updatePassword = (email, reset_token, password) => async (dispatch) => {
  await dispatch({ type: AUTH_IN_PROGRESS });
  requestUpdatePassword(email, reset_token, password)
    .then((response) => {
      dispatch({
        type: LOGIN_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_FAILED,
        payload: err.message,
      });
    });
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const closeRegisteredPopup = () => ({
  type: CLOSE_REGISTERED_POPUP,
});

export const openLoginPopup = () => ({
  type: OPEN_LOGIN_POPUP,
});

export const closeLoginPopup = () => ({
  type: CLOSE_LOGIN_POPUP,
});

export const refreshUserToken = (token) => (dispatch) => {
  requestRefreshJWT(token)
    .then((response) => {
      dispatch({
        type: REFRESH_JWT,
        payload: response.data,
      });
    })
    .catch(() => {
      // Treat as though we logged out. Very likely the user has reached the max
      // refresh timeout, and so needs to login again.
      dispatch({ type: LOGOUT_USER });
    });
};
