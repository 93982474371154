import { createStore, applyMiddleware } from 'redux';
import { logger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from 'reducers';
import { isDevelopment } from 'config';
import { save, load } from 'redux-localstorage-simple';
import { analyticsMiddleware } from 'util/analytics';

const LOCALSTORAGE_SETTINGS = { states: ['user', 'welcome'] };

export const loadLocalstorageAndShareState = (requestUrl = document.URL) => {
  if (!requestUrl) {
    return load(LOCALSTORAGE_SETTINGS);
  }

  const url = new URL(requestUrl);
  if (!url.pathname.startsWith('/share/')) {
    return load(LOCALSTORAGE_SETTINGS);
  }

  const data = url.pathname.substr(url.pathname.indexOf('share/') + 6);
  try {
    const decodedData = JSON.parse(decodeURIComponent(window.atob(data)));
    return {
      ...load(LOCALSTORAGE_SETTINGS),
      ...decodedData,
    };
  } catch (InvalidCharacterError) {
    // fall thru to default encodeing if non-base64 is provided
  }

  return load(LOCALSTORAGE_SETTINGS);
};

const configureStore = (initialState = loadLocalstorageAndShareState()) => {
  const middlewares = [];
  middlewares.push(thunk);
  middlewares.push(save(LOCALSTORAGE_SETTINGS));
  middlewares.push(analyticsMiddleware);
  if (isDevelopment()) {
    middlewares.push(logger);
  }

  let applied = applyMiddleware(...middlewares);
  if (isDevelopment()) {
    applied = composeWithDevTools(applied);
  }

  if (initialState !== null) {
    return createStore(reducers, initialState, applied);
  }

  return createStore(reducers, applied);
};

export default configureStore;
