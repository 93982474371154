import { UPDATE_TRENDS_POI } from 'actions';
import TRENDS from 'config/trends';

const DEFAULT_STATE = TRENDS.map((trend) => [trend.id, []]).reduce(
  (obj, [key, value]) => {
    // eslint-disable-next-line
    obj[key] = value;
    return obj;
  },
  {},
);

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case UPDATE_TRENDS_POI:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
