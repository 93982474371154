import {
  OPEN_NAV,
  CLOSE_NAV,
  SET_TAB,
} from 'actions';

export const TAB_LAYERS = 'TAB_LAYERS';
export const TAB_TRENDS = 'TAB_TRENDS';
export const TAB_DOWNLOAD = 'TAB_DOWNLOAD';
export const TAB_EXPLORE = 'TAB_EXPLORE';

const DEFAULT_STATE = {
  open: true,
  tab: TAB_LAYERS,
};

export default (state = DEFAULT_STATE, action) => {
  if (action.error) return state;

  switch (action.type) {
    case OPEN_NAV:
      return {
        ...state,
        open: true,
      };
    case CLOSE_NAV:
      return {
        ...state,
        open: false,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    default:
      return state;
  }
};
