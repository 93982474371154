import React from 'react';
import { useSelector } from 'react-redux';
import { WMSTileLayer } from 'react-leaflet';
import { EXPLORATIONS } from 'config/explore';
import { LAYERS } from 'config/layers';
import { findLayer } from 'reducers/layers';
import 'leaflet-velocity';

const Explore = () => {
  const layerType = useSelector((state) => state.explore.layerType);
  const isPost = useSelector((state) => state.explore.isPost);
  const layers = useSelector((state) => state.layers);

  if (!layerType) {
    return <div />;
  }

  const explore = EXPLORATIONS.find((e) => e.layerType === layerType);
  const time = isPost ? explore.post : explore.pre;
  const layer = findLayer(layers, explore.layerType);
  const layerConfig = LAYERS[layer.layerType];

  return (
    <div>
      <WMSTileLayer
        layers={layerConfig[time.year].layers}
        format={layerConfig[time.year].format}
        styles={layerConfig[time.year].styles}
        url={explore.url}
        opacity={layer.opacity / 100}
        transparent
      />
    </div>
  );
};

export default Explore;
