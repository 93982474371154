/* eslint-disable max-classes-per-file, class-methods-use-this */
import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import {
  LAYERS, LAYER_WEATHER, LAYER_PERIMETERS, DEFAULT_YEAR, makeWMSUrl,
} from 'config/layers';
import {
  TAB_LAYERS,
  TAB_TRENDS,
} from 'reducers/nav';
import { WMSTileLayer, withLeaflet, MapLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet-velocity';
import DataPoint from './datapoint';
import DataPointTrends from './datapoint-trends';

// wind layer mapping
class VelocityLayerUnwrapped extends MapLayer {
  // eslint-disable-next-line
  createLeafletElement({ data, opacity }) {
    let layer = null;
    const params = {
      data,
      colorScale: [
        '#008c9f',
        '#108899',
        '#208492',
        '#30818c',
        '#417d85',
        '#51797e',
        '#617578',
        '#727171',
        '#866a6a',
        '#9a6464',
        '#ae5e5e',
        '#c35757',
        '#d75151',
        '#eb4a4a',
        '#ff4444',
      ],
      displayValues: false,
      maxVelocity: 10,
      minVelocity: 0,
      velocityScale: 0.0075,
      particleMultiplier: 1 / 700,
      particleAge: 30,
      lineWidth: 1.35,
      opacity,
    };
    layer = L.velocityLayer(params);
    return layer;
  }

  updateLeafletElement(fromProps, toProps) {
    // eslint-disable-next-line
    this.leafletElement._canvasLayer._canvas.style.opacity = toProps.opacity;
  }
}
VelocityLayerUnwrapped.propTypes = {
  data: PropType.arrayOf(PropType.any).isRequired,
  opacity: PropType.number.isRequired,
};

const VelocityLayer = withLeaflet(VelocityLayerUnwrapped);

// fire perimeter mapping
class PerimeterLayerUnwrapped extends MapLayer {
  createLeafletElement({ data, opacity }) {
    let layer = null;
    const style = {
      fillColor: '#555555',
      color: '#f99157',
      weight: 1.5,
      opacity,
      fillOpacity: opacity / 1.333,
    };
    layer = L.geoJson(data, style);
    return layer;
  }

  updateLeafletElement(fromProps, toProps) {
    this.leafletElement.setStyle({
      opacity: toProps.opacity,
      fillOpacity: toProps.opacity / 1.333,
    });
  }
}
PerimeterLayerUnwrapped.propTypes = {
  opacity: PropType.number.isRequired,
};

const PerimeterLayer = withLeaflet(PerimeterLayerUnwrapped);

// all layer mapping
const Layers = ({ tab }) => {
  const layers = useSelector((state) => state.layers.layers);
  const visibleLayerTypes = useSelector(
    (state) => state.layers.visibleLayerTypes,
  );
  const poi = useSelector((state) => state.layers.poi);
  const [weatherData, setWeatherData] = useState(null);
  const showWeatherLayer = layers[0].visibility;
  const visibleLayers = layers.filter((l) => l.visibility);

  useEffect(() => {
    if (!showWeatherLayer || weatherData !== null) return;

    fetch(LAYERS[LAYER_WEATHER][DEFAULT_YEAR].url)
      .then((response) => response.json())
      .then(setWeatherData);
  }, [weatherData, showWeatherLayer]);

  const [perimeterData, setPerimeterData] = useState(null);
  const showPerimeterLayer = layers[1].visibility;

  useEffect(() => {
    if (!showPerimeterLayer || perimeterData !== null) return;

    fetch(LAYERS[LAYER_PERIMETERS][DEFAULT_YEAR].url)
      .then((response) => response.json())
      .then(setPerimeterData);
  }, [perimeterData, showPerimeterLayer]);

  return (
    <div>
      {poi && tab === TAB_LAYERS ? <DataPoint poi={poi} /> : null}
      {poi && tab === TAB_TRENDS ? <DataPointTrends poi={poi} /> : null}

      {visibleLayers
        .sort(
          (a, b) => visibleLayerTypes.indexOf(a.layerType)
            - visibleLayerTypes.indexOf(b.layerType),
        )
        .map((l) => {
          const map = LAYERS[l.layerType][l.year];

          return (
            <WMSTileLayer
              key={l.layerType}
              layers={map.layers}
              format={map.format}
              styles={map.styles}
              url={makeWMSUrl(LAYERS[l.layerType])}
              opacity={l.opacity / 100}
              transparent
            />
          );
        })}

      {showWeatherLayer && weatherData !== null && (
        <VelocityLayer data={weatherData} opacity={layers[0].opacity / 100} />
      )}
      {showPerimeterLayer && perimeterData !== null && (
        <PerimeterLayer data={perimeterData} opacity={layers[1].opacity / 100} />
      )}
    </div>
  );
};

Layers.propTypes = {
  tab: PropType.string,
};

Layers.defaultProps = {
  tab: null,
};

export default Layers;
